import {
  DocumentData,
  DocumentReference,
  DocumentSnapshot,
  Transaction,
  collection,
  getDocs,
  query,
  runTransaction,
  where,
} from "firebase/firestore";
import { doc } from "firebase/firestore";
import { TokenPaymentType } from "../TokenPaymentType";
import FirebaseConfig from "../../../config/firebase";
import { ProfileType } from "../../../types/ProfileType";
import firebase from "../../../config/firebase";

export default async function (
  payment: TokenPaymentType,
  action: "confirm" | "reject",
  reason: string = ""
) {
  let queryToken = query(
    collection(firebase.db, "token_payments"),
    where("confirmed", "==", true),
    where("is_cancel", "==", false),
    where("tx_hash", "==", payment.tx_hash)
  );
  const querySnapshot = await getDocs(queryToken);
  if (querySnapshot.size > 0) throw new Error("Transaction hash already used in another payment that already been confirmed!");

  await runTransaction(FirebaseConfig.db, async (transaction) => {  
    const tokenPaymentRef = doc(FirebaseConfig.db, "token_payments", payment.uid);
    const tokenPaymentSnap = await transaction.get(tokenPaymentRef);

    const salePhaseRef = doc(FirebaseConfig.db, "tokenSalePhases", payment.phase_uid);
    const salePhaseSnap = await transaction.get(salePhaseRef);

    const profileRef = doc(
      FirebaseConfig.db,
      "profile",
      payment.user_uid
    );

    const profileSnap = await transaction.get(profileRef);

    if (!tokenPaymentSnap.exists()) {
      throw new Error("payment not exists");
    }
    if (action == "confirm")
      return await updateConfirmPayment(
        transaction,
        tokenPaymentRef,
        payment,
        profileRef,
        profileSnap,
        salePhaseRef,
        salePhaseSnap
      );
    await updateRejectPayment(transaction, tokenPaymentRef, reason);
  });
}
async function updateRejectPayment(
  transaction: Transaction,
  tokenPaymentRef: DocumentReference<DocumentData>,
  reason: string
) {
  await transaction.update(tokenPaymentRef, {
    confirmed: true,
    is_cancel: true,
    reason: reason,
  });
}

async function updateConfirmPayment(
  transaction: Transaction,
  tokenPaymentRef: DocumentReference<DocumentData>,
  payment: TokenPaymentType,
  profileRef: DocumentReference<DocumentData>,
  profileSnap: DocumentSnapshot<DocumentData>,
  salePhaseRef: DocumentReference<DocumentData>,
  salePhaseSnap: DocumentSnapshot<DocumentData>,
) {
  let profileData = profileSnap.data() as ProfileType;
  
  if (profileData.referred_by) {
    const referrerProfileRef = doc(FirebaseConfig.db, "profile", profileData.referred_by);
    const referrerData = (await transaction.get(referrerProfileRef)).data() as ProfileType;
    let oldReferralToken = referrerData.referral_token ?? 0;
    
    transaction.update(referrerProfileRef, {
      referral_token: oldReferralToken + payment.referral_token
    });
  }

  const { sold }: any = salePhaseSnap.data();
  transaction.update(salePhaseRef, {
    sold: sold + payment.token
  })

  transaction.update(tokenPaymentRef, {
    confirmed: true,
  });

  if (!profileSnap.exists()) {
    transaction.set(profileRef, {
      token: payment.token,
      token_bonus: payment.token_bonus,
    });
  }
  else {
    let token =
      profileData && profileData.token && profileData.token > 0
        ? profileData.token
        : 0;
    let bonusToken =
      profileData && profileData.token_bonus && profileData.token_bonus > 0
        ? profileData.token_bonus
        : 0;
    transaction.update(profileRef, {
      token: token + payment.token,
      token_bonus: bonusToken + payment.token_bonus,
    });
  }
}
