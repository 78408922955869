import { Button } from '@mui/material';
import React, { useState } from 'react'
import { IoClose } from 'react-icons/io5';
import addBlockUserService from '../services/addBlockUserService';
import useCustomMutation from '../../../utils/useCustomMutation';
import { useQueryClient } from '@tanstack/react-query';

function PopUpAddBlockUser({ showSnackBar, setShowPopUp }: { showSnackBar: () => void, setShowPopUp: React.Dispatch<React.SetStateAction<boolean>> }) {
  const queryClient = useQueryClient();
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");

  function validateEmail(email: string) {
    return String(email)
      .toLowerCase()
      .match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
  };

  const { mutate } = useCustomMutation(addBlockUserService, {
    onSuccess: () => {
      queryClient.invalidateQueries(["blocked_list"]);
      showSnackBar();
      setShowPopUp(false);
    },
    onError: (err: string) => {
      setError(err.toString());
    }
  });

  function handleBlockUser() {
    setError("");
    if (!validateEmail(email)) setError("Email not valid");
    else mutate({ email })
  }

  return (
    <div className="fixed flex justify-center z-50 h-full inset-0 bg-black bg-opacity-60">
      <div className="relative p-5 min-w-[400px] h-fit m-auto rounded-2xl bg-white">
        <IoClose onClick={() => setShowPopUp(false)} className="cursor-pointer text-2xl absolute top-3 right-3" />
        <p className="font-medium mt-4">Type the user's email that you want to block</p>
        <input type="email" value={email} onChange={(e) => setEmail(e.currentTarget.value)} placeholder='sumitro@gmail.com' className="w-full mt-2 rounded-lg" />
        {error && <p className="mt-2 text-sm font-medium py-1 px-2 w-fit rounded-md bg-red-200 text-red-500">{error}</p>}
        <Button onClick={handleBlockUser} className="w-full mt-4 rounded-md" variant="contained" color="error">Block This User</Button>
      </div>
    </div>
  )
}

export default PopUpAddBlockUser;
