import React, { useEffect, useState } from 'react'
import PaymentHistoryItem from './components/PaymentHistoryItem';
import { useQuery } from '@tanstack/react-query';
import { getHistoryTokenFirstPage, getHistoryTokenNextPage, getHistoryTokenPrevPage } from './api/getHistoryTokenPayment';
import { Dropdown } from 'flowbite-react';
import TokenSalePhaseType from './TokenSalePhaseType';
import getTokenSalePhase from './api/getTokenSalePhase';
import PaymentHistoryItemSkeleton from './components/PaymentHistoryItemSkeleton';
import { TokenPaymentType } from '../token/TokenPaymentType';

function HistoryTokenPayment() {
  const [isLoading, setIsLoading] = useState(false);
  const [ableToNext, setAbleToNext] = useState(true);
  const [ableToPrev, setAbleToPrev] = useState(true);

  const [selectedPhase, setselectedPhase] = useState({ uid: "", title: "" });
  const [currData, setCurrData] = useState<{ data: Array<TokenPaymentType>, currPage:number, firstVisibleId?: string, lastVisibleId?: string }>({ data: [], currPage: 1 });

  const { isLoading: isHistoryTokenLoad, data } = useQuery(["HistoryTokenPayment", selectedPhase.uid], () => getHistoryTokenFirstPage(selectedPhase.uid), { enabled: selectedPhase.uid !== "" });
  const { data: salePhaseData } = useQuery<TokenSalePhaseType[], Error>(["TokenSalePhase"], getTokenSalePhase);

  function handleNext() {
    if (currData.lastVisibleId) {
      setIsLoading(true);
      setAbleToPrev(true);
      getHistoryTokenNextPage(selectedPhase.uid, currData.lastVisibleId)
      .then(res => {
        if (!res) setAbleToNext(false);
        else setCurrData(prev =>  ({ data: res.result, currPage: prev.currPage + 1, firstVisibleId: res.firstVisibleId, lastVisibleId: res.lastVisibleId }));
        setIsLoading(false);
      });
    }
  }

  function handlePrev() {
    if (currData.firstVisibleId) {
      setIsLoading(true);
      setAbleToNext(true);
      getHistoryTokenPrevPage(selectedPhase.uid, currData.firstVisibleId)
      .then(res => {
        if (!res) setAbleToPrev(false);
        else setCurrData(prev => ({ data: res.result, currPage: prev.currPage - 1, firstVisibleId: res.firstVisibleId, lastVisibleId: res.lastVisibleId }));
        setIsLoading(false);
      });
    }
  }

  useEffect(() => {
    if (data && data?.result) {
      setCurrData({ data: data.result, currPage: 1, firstVisibleId: data.firstVisibleId, lastVisibleId: data.lastVisibleId });
    }
  }, [data]);

  if (!salePhaseData) return <p>Sale Phase not exists yet </p>;
  return (
    <div className="p-2 w-full">
      <p className="font-bold text-2xl mb-5">History Token Payment</p>
      <Dropdown label={selectedPhase.title || "Choose Sale Phase"}>
        {salePhaseData.map((value, index) => {
          return <Dropdown.Item onClick={() => { setAbleToNext(true); setselectedPhase({ uid: value.uid || "", title: value.title })} } key={index}>{value.title}</Dropdown.Item>
        })}
      </Dropdown>
      
      <div className="relative h-[90%] overflow-auto my-4 mx-auto bg-white">
        <div className="bg-gray-100 rounded-md flex justify-between min-w-[750px] px-6 py-3">
          <p className="font-bold min-w-[56px] lg:min-w-[170px] flex-shrink-0 text-sm">No</p>
          <p className="font-bold flex-1 flex-shrink-0  text-sm">Amount</p>
          <p className="font-bold flex-1 flex-shrink-0  text-sm">Token</p>
          <p className="font-bold flex-1 flex-shrink-0  text-sm">Referral Token</p>
          <p className="font-bold flex-1 flex-shrink-0 text-sm">Email</p>
          <p className="font-bold flex-1 flex-shrink-0 text-sm">Payment Date</p>
          <p className="font-bold flex-1 flex-shrink-0 text-sm">Total Token</p>
        </div>
        {((isHistoryTokenLoad || isLoading) && selectedPhase.uid) && Array(10).fill(1).map((e, i) => <PaymentHistoryItemSkeleton key={i} />)}
        {(!isLoading && selectedPhase.uid) && currData?.data?.map((e, index) => {
          const startItemNo = (currData.currPage - 1) * 10 + 1 + index;
          return <PaymentHistoryItem payId={startItemNo} data={e} key={index} />
        })}
        
        <div className="absolute bottom-0 flex gap-3 mt-auto">
          <button disabled={!ableToPrev} onClick={handlePrev} className="rounded-md font-medium px-4 py-2 disabled:bg-blue-300 bg-blue-500 text-white">Previous</button>
          <button disabled={!ableToNext} onClick={handleNext} className="rounded-md font-medium px-[29px] py-2 disabled:bg-blue-300 bg-blue-500 text-white">Next</button>
        </div>
      </div>
    </div>
  )
}

export default HistoryTokenPayment;
