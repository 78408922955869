import { updatePassword, reauthenticateWithCredential, getAuth, EmailAuthProvider } from "firebase/auth";

const auth = getAuth();

export default async function ({
    oldPassword,
    newPassword,
}: {
    oldPassword: string;
    newPassword: string;
}) {
    const user = auth.currentUser!;
    const cred = EmailAuthProvider.credential(user.email!, oldPassword);
    await reauthenticateWithCredential(user, cred)
    await updatePassword(auth.currentUser!, newPassword);
}
