import { useMutation } from "@tanstack/react-query";
import { FormEvent, useState } from "react";
import createTokenSalePhase from "./api/createTokenSalePhase";
import { useNavigate } from "react-router-dom";
import TokenSaleForm from "./TokenSaleForm";
import TokenSalePhaseType from "./TokenSalePhaseType";
import { useAppSelector } from "../../hook";

export default function () {
  const [phase, setPhase] = useState<TokenSalePhaseType>({
    title: '',
    type: '',
    start_at: new Date(),
    end_at: new Date(),
    amount: 0,
    sold: 0,
    max_purchase: 0,
    price: 0,
    participated_user: [],
    visited_user: []
  })

  const mutation = useMutation({
    mutationKey: ["submit phase"],
    mutationFn: async () => {
      createTokenSalePhase(phase);
    },
    onSuccess: () => {
      navigate("/token-sale-phase");
    }
  })
  const navigate = useNavigate();
  const handleSubmit = (e: FormEvent) => {
    mutation.mutate();
  }
  const permissions = useAppSelector((states) => states.userPermissions.permissions);
  
  if (!permissions.includes("token_sale_phase") && !permissions.includes("all")) return(
    <div className="w-full flex flex-col justify-center">
      <p className="text-2xl text-center my-auto animate-bounce">You don't have permission to access this feature</p>
    </div>
  )
  
  return (
    <div className="w-full mx-2 flex flex-col gap-y-2">
      <div className=" text-2xl font-bold">Create new Phase</div>
      <TokenSaleForm phase={phase} setPhase={setPhase} handleSubmit={handleSubmit} />
    </div>
  )
}