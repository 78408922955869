import createSagaMiddleware from '@redux-saga/core'
import { PreloadedState, combineReducers, configureStore } from '@reduxjs/toolkit'
import loadingReducer from './features/utils/loadingSlice';
import userPermissionsReducer from './features/utils/userPermissionsSlice';

const rootReducer = combineReducers({
  loading: loadingReducer,
  userPermissions: userPermissionsReducer
})
const sagaMiddleware = createSagaMiddleware();

export const setupStore = (preloadedState?: PreloadedState<RootState>) => {
  return configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware()
        .prepend(
          // correctly typed middlewares can just be used
          sagaMiddleware
        )
  })
}

export const store = setupStore();

export type RootState = ReturnType<typeof rootReducer>
export type AppStore = ReturnType<typeof setupStore>
export type AppDispatch = AppStore['dispatch']

