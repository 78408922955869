import { Textarea } from "flowbite-react";
import {
  Button, FileInput, Label, Modal, TextInput 
} from "flowbite-react";
import { FormEvent, useEffect, useRef, useState } from "react";
import { useQueryClient } from "@tanstack/react-query";;
import { ProductCreate, ProductCreateInput, createProduct } from "./apiProduct";
import moment from "moment";
import useCustomMutation from "../../utils/useCustomMutation";

export default function ({ show, onClose }: { show: boolean, onClose: () => void }) {
  const queryClient = useQueryClient();
  const mutation = useCustomMutation(createProduct, {
    onSuccess: () => {
      queryClient.invalidateQueries(['products'])
      onClose()
    },
    onError: (e: Error) => {
      setError(e.message)
    }
  })
  const [error, setError] = useState("")
  const handleCreate = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    if (input.image == null) return setError("image is required")
    if (input.logo == null) return setError("logo is required")
    if (input.background_cover == null) return setError("Background cover is required")
    mutation.mutate(input as ProductCreateInput)
  }

  const [input, setInput] = useState<ProductCreate>({
    name: "",
    description: "",
    usdt_amount: 1,
    worth_price: 1000,
    lowest_price: 10,
    highest_price: 100,
    image: [],
    logo: null,
    background_cover: null,
    start_time: new Date(),
    end_time: new Date(),
    is_calculated: false,
    is_extended: false
  })

  const handleChangeInput = (e: FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (e.currentTarget.type == "datetime-local") {
      return setInput({ ...input, [e.currentTarget.id]: new Date(Date.parse(e.currentTarget.value)) })
    }
    setInput({ ...input, [e.currentTarget.id]: e.currentTarget.value })
  }

  const handleOnFileChange = (e: FormEvent<HTMLInputElement>) => {
    if (e.currentTarget.files == null) return;
    let tempImages: File[] = [];
    Array.from(e.currentTarget.files).forEach(file => {
      tempImages.push(file)
    });
    setInput({ ...input, image: tempImages })
  }

  const handleOnBgCoverChange = (e: FormEvent<HTMLInputElement>) => {
    let tempImage = e.currentTarget.files == null ? null : e.currentTarget.files[0];
    setInput({ ...input, background_cover: tempImage })
  }

  const handleOnLogoChange = (e: FormEvent<HTMLInputElement>) => {
    let tempLogo = e.currentTarget.files == null ? null : e.currentTarget.files[0];
    setInput({ ...input, logo: tempLogo })
  }
  const rootRef = useRef<HTMLDivElement>(null);

  return (
    <div ref={rootRef}>
      <Modal
        show={show}
        onClose={onClose}
        root={rootRef.current ?? undefined}
      >
        <Modal.Header>
          Create new product
        </Modal.Header>
        <Modal.Body>
          <form className="flex flex-col gap-4" onSubmit={handleCreate}>
            <div>
              <div className="mb-2 block">
                <Label
                  htmlFor="name"
                  value="name"
                />
              </div>
              <TextInput
                key="nameInput"
                onChange={handleChangeInput}
                value={input.name}
                id="name"
                type="string"
                placeholder="Iphone 13 pro max"
                required={true}
              />
            </div>
            <div>
              <div className="mb-2 block">
                <Label
                  htmlFor="description"
                  value="description"
                />
              </div>
              <Textarea
                onChange={handleChangeInput}
                id="description"
                required={true}
                value={input.description}
              />
            </div>
            <div>
              <div className="mb-2 block">
                <Label
                  htmlFor="usdt_amount"
                  value="usdt_amount"
                />
              </div>
              <TextInput
                onChange={handleChangeInput}
                id="usdt_amount"
                type="number"
                required={true}
                value={input.usdt_amount}
              />
            </div>
            <div>
              <div className="mb-2 block">
                <Label
                  htmlFor="worth_price"
                  value="worth_price"
                />
              </div>
              <TextInput
                onChange={handleChangeInput}
                id="worth_price"
                type="number"
                required={true}
                value={input.worth_price}
              />
            </div>
            <div>
              <div className="mb-2 block">
                <Label
                  htmlFor="lowest_price"
                  value="lowest_price"
                />
              </div>
              <TextInput
                onChange={handleChangeInput}
                id="lowest_price"
                type="number"
                required={true}
                value={input.lowest_price}
              />
            </div>
            <div>
              <div className="mb-2 block">
                <Label
                  htmlFor="highest_price"
                  value="highest_price"
                />
              </div>
              <TextInput
                onChange={handleChangeInput}
                id="highest_price"
                type="number"
                required={true}
                value={input.highest_price}
              />
            </div>
            <div>
              <div className="mb-2 block">
                <Label
                  htmlFor="image"
                  value="image"
                />
              </div>
              <FileInput
                onChange={handleOnFileChange}
                id="image"
                required={true}
                accept='image/*'
                multiple
              />
            </div>
            <div>
              <div className="mb-2 block">
                <Label
                  htmlFor="background-cover"
                  value="background-cover"
                />
              </div>
              <FileInput
                onChange={handleOnBgCoverChange}
                id="background-cover"
                required={true}
                accept='image/*'
              />
            </div>
            <div>
              <div className="mb-2 block">
                <Label
                  htmlFor="logo"
                  value="logo"
                />
              </div>
              <FileInput
                onChange={handleOnLogoChange}
                id="logo"
                required={true}
                accept='image/*'
              />
            </div>
            <div>
              <div className="mb-2 block">
                <Label
                  htmlFor="start_time"
                  value="start_time"
                />
              </div>
              <TextInput
                onChange={handleChangeInput}
                id="start_time"
                type="datetime-local"
                required={true}
                value={moment(input.start_time).format("YYYY-MM-DDTkk:mm")}
              />
            </div>
            <div>
              <div className="mb-2 block">
                <Label
                  htmlFor="end_time"
                  value="end_time"
                />
              </div>
              <TextInput
                onChange={handleChangeInput}
                id="end_time"
                type="datetime-local"
                required={true}
                value={moment(input.end_time).format("YYYY-MM-DDTkk:mm")}
              />
            </div>
            {error && error}
            <Button type="submit">
              Submit
            </Button>
          </form>
        </Modal.Body>
      </Modal>

    </div>

  )
}
