import {
  collection,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import FirebaseConfig from "../../../config/firebase";
import { DepositType } from "../../deposit/DepositType";

export default async (txHash: string) => {
  let queryToken = query(
    collection(FirebaseConfig.db, "transactions"),
    where("transaction_hash", "==", txHash)
  );

  const querySnapshot = await getDocs(queryToken);
  const data: Array<DepositType> = [];
  querySnapshot.forEach((doc) => {
    let tempData = doc.data();
    data.push({
      uid: doc.id,
      email: tempData["email"],
      confirmed: tempData["confirmed"],
      created_at: new Date(tempData["created_at"]["seconds"] * 1000),
      is_cancel: tempData["is_cancel"],
      user_uid: tempData["user_uid"],
      transaction_hash: tempData["transaction_hash"],
      amount: tempData["usdt_amount"],
      image: tempData["image"],
    });
  });
  return data;
};
