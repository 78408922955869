import { useQueryClient } from "@tanstack/react-query"
import adminPaymentConfirmationService from "./adminPaymentConfirmationService"
import { PaymentType } from "./PaymentType"
import { FormEvent, Fragment, useEffect, useState } from "react"
import { Button, Modal } from "flowbite-react"
import { FaCheck } from "react-icons/fa"
import useCustomMutation from "../../utils/useCustomMutation"

export function ConfirmPaymentModal({ selected_payment }: { selected_payment: PaymentType }) {
  const queryClient = useQueryClient();
  const mutation = useCustomMutation((selected_payment: PaymentType) => adminPaymentConfirmationService(selected_payment, "confirm"))

  const [show, setShow] = useState(false)
  const onClick = () => {
    setShow(true)
  }
  const handlePaymentConfirmation = () => {
    mutation.mutate(selected_payment, {
      onSuccess: () => {
        queryClient.invalidateQueries(["unconfirmed payment"])
        queryClient.invalidateQueries(["active transaction"])
        setShow(false)
      }
    })
  }
  const handleClose = () => {
    setShow(false)
  }
  return <Fragment>
    <Button className="w-full" color={"success"} onClick={onClick}><FaCheck className="mr-1" size={14} color={"white"} />Confirm</Button>
    <Modal
      show={show}
      onClose={handleClose}
    >
      <Modal.Header>
        Payment Confirmation
      </Modal.Header>
      <Modal.Body>
        Are you sure to confirm this payment
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={handlePaymentConfirmation} disabled={mutation.isLoading}>
          I accept
        </Button>
        <Button
          color="gray"
          onClick={handleClose}
          disabled={mutation.isLoading}
        >
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  </Fragment>
}

export function RejectPaymentModal({ selected_payment }: { selected_payment: PaymentType }) {
  const queryClient = useQueryClient();
  const [reason, setReason] = useState("")
  const mutation = useCustomMutation(({ selected_payment, reason }: { selected_payment: PaymentType, reason: string }) => adminPaymentConfirmationService(selected_payment, "reject", reason))
  const handleOnChange = (e: FormEvent<HTMLTextAreaElement>) => {
    setReason(e.currentTarget.value)
  }
  const [show, setShow] = useState(false)
  const onClick = () => {
    setShow(!show)
  }
  const handlePaymentRejection = () => {
    mutation.mutate({ selected_payment, reason }, {
      onSuccess: () =>
        queryClient.invalidateQueries(["unconfirmed payment"])
    })
    setShow(false)
  }
  const handleClose = () => {
    setShow(false)
  }
  return <Fragment>
    <Button className="w-full" color={"failure"} onClick={onClick}><FaCheck className="mr-1" size={14} color={"white"} />Reject</Button>
    <Modal
      show={show}
      onClose={handleClose}
    >
      <Modal.Header>
        Payment Reject
      </Modal.Header>
      <Modal.Body>
        <div>Are you sure to reject this payment</div>

        <form>
          <div className="relative z-0 w-full mb-6 group mt-2">
            <textarea onBlur={handleOnChange} name="Reason" id="Reason" className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " required defaultValue={reason} />
            <label htmlFor="Reason" className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Reason address</label>
          </div>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={handlePaymentRejection} color={"failure"} disabled={mutation.isLoading}>
          Reject
        </Button>
        <Button
          color="gray"
          onClick={handleClose}
          disabled={mutation.isLoading}
        >
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  </Fragment>
}