import { collection, getDocs, query, where } from "firebase/firestore";
import FirebaseConfig from "../../../config/firebase";

export default async function() {
  const q = query(collection(FirebaseConfig.db, "profile"),where("low_level_admin", "==", true));
  const querySnapshot = await getDocs(q);
  const data: Array<{email: string}> = [];
  querySnapshot.forEach((doc) => {
    let tempData = doc.data();
    data.push({
      email: tempData.email
    });
  })
  return data;
}