import { Button } from 'flowbite-react';
import React, { useState } from 'react'
import { IoClose } from 'react-icons/io5';
import useCustomMutation from '../../utils/useCustomMutation';
import deleteNewsService from './services/deleteNewsService';
import { useQueryClient } from '@tanstack/react-query';

function PopUpDeleteNews({ doc_uid, setPopUpSocialMedia }: { doc_uid: string, setPopUpSocialMedia: React.Dispatch<React.SetStateAction<string>> }) {
  const queryClient = useQueryClient();
  const [error, setError] = useState("");
  
  const { mutate: deleteNews } = useCustomMutation(deleteNewsService, {
    onSuccess: () => {
      queryClient.invalidateQueries(["social media news"])
      setPopUpSocialMedia("");
    },
    onError: (e: Error) => {
      setError(e.message);
    }
  })

  return (
    <div className="fixed flex justify-center z-50 h-full inset-0 bg-black bg-opacity-60">
      <div className="relative p-4 min-w-[400px] h-fit m-auto rounded-2xl bg-white">
        <IoClose onClick={() => setPopUpSocialMedia("")} className="absolute text-xl right-5 cursor-pointer" />
        <p className="text-center font-bold text-xl">Delete Confirmation</p>
        <p className="mt-2">Are you sure you want to delete this news ?</p>
        {error && error}
        <div className="mt-3 flex gap-3">
          <Button onClick={() => deleteNews({ doc_uid })} className="flex-1">Yes</Button>
          <Button onClick={() => setPopUpSocialMedia("")} className="flex-1 bg-red-600 hover:bg-red-500">No</Button>
        </div>
      </div>
    </div>
  )
}

export default PopUpDeleteNews;
