import { useQuery } from "@tanstack/react-query";
import { Fragment } from "react";
import DepositItemCard from "./components/DepositItemCard";
import { getPayment } from "./apiDeposit";
export default function () {
  const { data, refetch } = useQuery(["unconfirmed deposit payment"], () => getPayment());
  return (
    <div>
      <div className="text-2xl font-bold">Latest Deposit</div>
      <div className="flex flex-col gap-y-2">
        {data?.map((item, index) => {
          return (
            <Fragment key={index}>
              <DepositItemCard refetchUnconfirmed={refetch} item={item} key={index} />
            </Fragment>
          );
        })}
      </div>
    </div>
  );
}