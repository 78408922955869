import { useMutation, useQuery } from "@tanstack/react-query";
import getVoucher from './getVoucher';
import { Fragment, useState } from "react";
import ItemCard from "../utils/ItemCard";
import { Button } from "flowbite-react";
import { useNavigate } from "react-router-dom";
import deleteVoucher from "./deleteVoucher";
import { useAppSelector } from "../../hook";
export default function () {
  const query = useQuery(["voucher"], () => getVoucher());
  const navigate = useNavigate()
  const handleCreate = () => { navigate("/voucher/create") }
  const [error, setError] = useState("");
  const mutationDelete = useMutation({
    mutationFn: (uid: string) => deleteVoucher(uid),
    onSuccess: () => {
      query.refetch()
    },
    onError: (error: any) => {
      setError(error.message)
    }
  })
  const handleDelete = (uid: string) => {
    mutationDelete.mutate(uid)
  }

  const permissions = useAppSelector((states) => states.userPermissions.permissions);
  
  if (!permissions.includes("voucher") && !permissions.includes("all")) return(
    <div className="flex flex-col justify-center w-full">
      <p className="my-auto text-2xl text-center animate-bounce">You don't have permission to access this feature</p>
    </div>
  )

  return (
    <div>
      <div className="text-2xl font-bold">Voucher list</div>
      <div><Button onClick={handleCreate} >create</Button></div>
      {error !== "" && error}
      <div className="flex flex-wrap gap-2 mt-4">
        {query.data?.map((item, index) => {
          return (
            <div key={index}>
              <ItemCard item={item} key={index} onDelete={() => { handleDelete(item.uid!) }} />
            </div>
          );
        })}
      </div>
    </div>
  );
}
