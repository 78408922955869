import { useInfiniteQuery, useQuery } from "@tanstack/react-query";
import { Button } from "flowbite-react";
import { Fragment } from "react";
import adminFetchInfiniteScrollService from "./api/adminFetchInfiniteScrollService";
import PaymentItemCard from "./components/PaymentItemCard";
import { getPayment } from "./apiPayment";
export default function () {
  const query = useQuery(["unconfirmed payment"], () => getPayment());
  return (
    <div>
      <div className="text-2xl font-bold">Latest Ticket Payment</div>
      <div className="flex flex-col gap-y-2">
        {query.data?.map((item, index) => {
          return (
            <Fragment key={index}>
              <PaymentItemCard item={item} key={index} />
            </Fragment>
          );
        })}
      </div>
    </div>
  );
}
