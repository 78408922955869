import { useMutation } from "@tanstack/react-query";
import { FormEvent, useState } from "react";
import createTokenSalePhase from "./api/createTokenSalePhase";
import { useLocation, useNavigate } from "react-router-dom";
import TokenSalePhaseType from "./TokenSalePhaseType";
import TokenSaleForm from "./TokenSaleForm";
import updateTokenSalePhase from "./api/updateTokenSalePhase";
import { useAppSelector } from "../../hook";

export default function () {
  const permissions = useAppSelector((states) => states.userPermissions.permissions);
  
  if (!permissions.includes("token_sale_phase") && !permissions.includes("all")) return(
    <div className="w-full flex flex-col justify-center">
      <p className="text-2xl text-center my-auto animate-bounce">You don't have permission to access this feature</p>
    </div>
  )

  const { state }: { state: TokenSalePhaseType } = useLocation()

  const [phase, setPhase] = useState<TokenSalePhaseType>({
    uid: state.uid,
    title: state.title,
    type: state.type,
    start_at: state.start_at,
    end_at: state.end_at,
    price: state.price,
    amount: state.amount,
    max_purchase: state.max_purchase,
    visited_user: state.visited_user,
    participated_user: state.participated_user,
  })

  const mutation = useMutation({
    mutationKey: ["submit phase"],
    mutationFn: async () => {
      updateTokenSalePhase(phase);
    },
    onSuccess: () => {
      navigate("/token-sale-phase");
    }
  })
  const navigate = useNavigate();
  const handleSubmit = (e: FormEvent) => {
    mutation.mutate();
  }
  
  return (
    <div className="w-full mx-2 flex flex-col gap-y-2">
      <div className=" text-2xl font-bold">Edit Phase</div>
      <TokenSaleForm phase={phase} setPhase={setPhase} handleSubmit={handleSubmit} />
    </div>
  )
}