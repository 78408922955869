import { FileInput, Textarea } from "flowbite-react";
import {
    Button, Label, Modal, TextInput
} from "flowbite-react";
import { FormEvent, useEffect, useRef, useState } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import editProduct from "./editProduct";
import useCustomMutation from "../../utils/useCustomMutation";
import { Product } from "../../types/ProductType";

type ProductType = {
    name?: string,
    description?: string,
    image?: Array<File>,
    background_cover?: File,
    logo?: File,
}

export default function ({ show, onClose, data }: { show: boolean, onClose: () => void, data: Product }) {
    const queryClient = useQueryClient();
    const [error, setError] = useState("")

    const mutation = useCustomMutation(editProduct)

    const handleUpdate = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        mutation.mutate({ uid: data.uid, ...inputChangeValues }, {
            onSuccess: () => {
                queryClient.invalidateQueries(['products'])
                setInputChangeValues({ ...inputChangeValues, name: "", description: "" })
                onClose()
                setIsChange(false)
            },
            onError: (e: any) => {
                setError(e.message)
            }
        })
    }
    const [input, setInput] = useState<ProductType>({
        name: "",
        description: ""
    })

    const [inputChangeValues, setInputChangeValues] = useState<ProductType>({
        name: "",
        description: ""
    })

    const [isChange, setIsChange] = useState(false);

    const handleChangeInput = (e: FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setInput({ ...input, [e.currentTarget.id]: e.currentTarget.value })
        setInputChangeValues({ ...input, [e.currentTarget.id]: e.currentTarget.value })
        setIsChange(true)
    }

    const handleOnFileChange = (e: FormEvent<HTMLInputElement>) => {
        if (e.currentTarget.files == null) return;
        let tempImages: File[] = [];
        Array.from(e.currentTarget.files).forEach(file => {
            tempImages.push(file)
        });
        setInputChangeValues({ ...input, image: tempImages })
        setIsChange(true)
    }

    const handleOnBgCoverChange = (e: FormEvent<HTMLInputElement>) => {
        if (e.currentTarget.files == null) return;
        setInputChangeValues({ ...input, background_cover: e.currentTarget.files[0] })
        setIsChange(true)
    }

    const handleOnLogoChange = (e: FormEvent<HTMLInputElement>) => {
        if (e.currentTarget.files == null) return;
        setInputChangeValues({ ...input, logo: e.currentTarget.files[0] })
        setIsChange(true)
    }
    const rootRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (data) {
            setInput({
                ...input,
                name: data.name,
                description: data.description
            })
        }
    }, [data])

    return (
        <div ref={rootRef}>
            <Modal
                show={show}
                onClose={onClose}
                root={rootRef.current ?? undefined}
            >
                <Modal.Header>
                    Edit Product
                </Modal.Header>
                <Modal.Body>
                    <form className="flex flex-col gap-4" onSubmit={handleUpdate}>
                        <div>
                            <div className="mb-2 block">
                                <Label
                                    htmlFor="name"
                                    value="name"
                                />
                            </div>
                            <TextInput
                                key="nameInput"
                                onChange={handleChangeInput}
                                value={input.name}
                                id="name"
                                type="string"
                                placeholder="name"
                                required={true}
                            />
                        </div>
                        <div>
                            <div className="mb-2 block">
                                <Label
                                    htmlFor="description"
                                    value="description"
                                />
                            </div>
                            <Textarea
                                onChange={handleChangeInput}
                                id="description"
                                required={false}
                                value={input.description}
                            />
                        </div>
                        <div>
                            <div className="mb-2 block">
                                <Label
                                    htmlFor="image"
                                    value="image"
                                />
                            </div>
                            <div className={`mb-2 flex flex-wrap gap-4 justify-start ${inputChangeValues.image !== undefined ? 'hidden' : 'block'}`}>
                                <img className=" object-contain w-20 h-20 rounded" src={data.image ? data.image[0] : ""} />
                            </div>
                            <FileInput
                                onChange={handleOnFileChange}
                                id="image"
                                required={false}
                                accept='image/*'
                                multiple
                            />
                        </div>
                        <div>
                            <div className="mb-2 block">
                                <Label
                                    htmlFor="background-cover"
                                    value="background-cover"
                                />
                            </div>
                            <img className={`mb-2 flex-1 object-contain w-20 h-20 rounded ${inputChangeValues.background_cover !== undefined ? 'hidden' : 'block'}`} src={data.background_cover} />
                            <FileInput
                                onChange={handleOnBgCoverChange}
                                id="background-cover"
                                required={false}
                                accept='image/*'
                            />
                        </div>
                        <div>
                            <div className="mb-2 block">
                                <Label
                                    htmlFor="logo"
                                    value="logo"
                                />
                            </div>
                            <img className={`mb-2 flex-1 object-contain w-20 h-20 rounded ${inputChangeValues.logo !== undefined ? 'hidden' : 'block'}`} src={data.logo} />

                            <FileInput
                                onChange={handleOnLogoChange}
                                id="logo"
                                required={false}
                                accept='image/*'
                            />
                        </div>
                        {error && error}
                        <Button disabled={!isChange} type="submit">
                            Submit
                        </Button>
                    </form>
                </Modal.Body>
            </Modal>

        </div>

    )
}