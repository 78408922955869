import {
  collection,
  getDocs,
} from "firebase/firestore";
import FirebaseConfig from "../../../config/firebase";
import Product from "../ProductType";
import AuctionData from "../AuctionDataType";

export default async function () {
  const auctionDataRef = collection(FirebaseConfig.db, "auction_data");
  const productsRef = collection(FirebaseConfig.db, "products");
  const querySnapshotAuction = await getDocs(auctionDataRef);
  const querySnapshotProducts = await getDocs(productsRef);
  const productsId: Array<string> = [];
  const data: Array<Product> = [];
  const auctionData: Array<AuctionData> = [];

  querySnapshotAuction.forEach((doc) => {
    const tempData = doc.data();
    productsId.push(doc.id);
    auctionData.push({
      uid: doc.id,
      anonymous_visit: tempData.anonymous_visit,
      collected_amount: tempData.collected_amount,
      joined_user: tempData.joined_user,
      visited_user: tempData.visited_user,
      winner_email: tempData.winner_email,
    })
  });

  querySnapshotProducts.forEach((doc) => {
    const tempData = doc.data();
    data.push({
      uid: doc.id,
      ticket_cost: tempData.ticket_cost,
      worth_price: tempData.worth_price,
      highest_price: tempData.highest_price,
      lowest_price: tempData.lowest_price,
      image: tempData.image,
      logo: tempData.logo,
      name: tempData.name,
      description: tempData.description,
      created_at: new Date(tempData.created_at.seconds * 1000),
      start_time: new Date(tempData.start_time.seconds * 1000),
      end_time: new Date(tempData.end_time.seconds * 1000),
    });
  });

  const finalData = auctionData.map((item) => {
    const filteredData = data.filter((e) => e.uid === item.uid).shift();
    if (!filteredData) return {};
    const { ticket_cost, description, created_at, end_time, highest_price, image, logo, lowest_price, name, start_time, worth_price } = filteredData;
    return {
      anonymous_visit: item.anonymous_visit,
      collected_amount: item.collected_amount,
      joined_user: item.joined_user,
      visited_user: item.visited_user,
      winner_email: item.winner_email,
      ticket_cost,
      worth_price,
      highest_price,
      lowest_price,
      image,
      logo,
      name,
      description,
      created_at,
      start_time,
      end_time,
    }
  })
  finalData.sort((a, b) => {
    if (!a.created_at || !b.created_at) return 0;
    return b.created_at.getTime() - a.created_at.getTime()
  });

  return finalData;
}
