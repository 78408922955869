import { Button } from 'flowbite-react';
import { SocialMediaNewsType } from './SocialMediaNewsType';
import PopUpEditNews from './PopUpEditNews';
import PopUpDeleteNews from './PopUpDeleteNews';

function NewsItem({ popUpSocialMedia, setPopUpSocialMedia, data }: 
  { popUpSocialMedia: string,
    setPopUpSocialMedia: React.Dispatch<React.SetStateAction<string>>, 
    data: SocialMediaNewsType}) {
  const { doc_uid, platform, title, link } = {...data};

  return (
    <div className="p-3 rounded-2xl shadow-lg min-w-[320px] max-w-xs">
      {popUpSocialMedia === `edit-${doc_uid}` && <PopUpEditNews setPopUpSocialMedia={setPopUpSocialMedia} data={data} />}
      {popUpSocialMedia === `delete-${doc_uid}` && <PopUpDeleteNews setPopUpSocialMedia={setPopUpSocialMedia} doc_uid={data.doc_uid} />}

      <p className="font-medium">Platform: {platform}</p>
      <p className="font-medium mt-3">Title: {title}</p>
      <p className="font-medium mt-3">Link: <a href={`https://${link}`} target='blank' className="cursor-pointer text-blue-500 underline">https://{link}</a></p>
      <div className="mt-4 flex gap-3">
        <Button onClick={() => setPopUpSocialMedia(`edit-${doc_uid}`)} className="flex-1">Edit</Button>
        <Button onClick={() => setPopUpSocialMedia(`delete-${doc_uid}`)} className="flex-1 bg-red-600 hover:bg-red-500">Delete</Button>
      </div>
    </div>
  )
}

export default NewsItem;
