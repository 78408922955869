import { addDoc, collection } from "firebase/firestore";
import TokenSalePhaseType from "../TokenSalePhaseType";
import FirebaseConfig from "../../../config/firebase";

export default async function (phase: TokenSalePhaseType) {
  await addDoc(collection(FirebaseConfig.db, "tokenSalePhases"), {
    ...phase,
    sold: 0,
  });
}
