import { collection, doc, getDoc, getDocs, query } from "firebase/firestore";
import { useQuery } from "@tanstack/react-query";;
import ProductCard from "./ProductCard";
import { getProducts } from "./apiProduct";
import { Product } from "../../types/ProductType";

export default function () {

  const { isLoading, error, data } = useQuery<Product[], Error>(["products"], getProducts)

  return <div>
    {isLoading && "Loading..."}
    {error && `Error : ${error.message}`}
    {data && data.map((item, index) => <ProductCard key={index} data={item} />)}
  </div>
}