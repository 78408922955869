import React from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css'

function PaymentHistoryItemSkeleton() {
  return (
    <div className="bg-[#FBFBF9] rounded-md relative flex justify-between h-14 min-w-[750px] mt-2 px-6 py-3 cursor-pointer">
      <Skeleton duration={0.6} containerClassName="my-auto flex-1"  width={56} height={16} />
      <Skeleton duration={0.6} containerClassName="my-auto flex-1" width={86} height={20} />
      <Skeleton duration={0.6} containerClassName="my-auto flex-1" width={81} height={32} />
      <Skeleton duration={0.6} containerClassName="my-auto flex-1" width={102} height={20} />
      <Skeleton duration={0.6} containerClassName="my-auto flex-1" width={108} height={24} />
    </div>
  )
}

export default PaymentHistoryItemSkeleton;