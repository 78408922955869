import {
  collection,
  getDocs,
  orderBy,
  query,
  where,
  limit,
  startAfter,
} from "firebase/firestore";
import { PaymentType } from "./PaymentType";
import FirebaseConfig from "../../config/firebase";

export const getPayment = async (created_at?: Date) => {
  let q = query(
    collection(FirebaseConfig.db, "payments"),
    where("confirmed", "==", false),
    orderBy("created_at", "asc"),
    limit(5)
  );
  if (created_at) {
    q = query(
      collection(FirebaseConfig.db, "payments"),
      where("confirmed", "==", false),
      orderBy("created_at", "asc"),
      startAfter(created_at),
      limit(5)
    );
  }
  const querySnapshot = await getDocs(q);
  const data: Array<PaymentType> = [];
  querySnapshot.forEach((doc) => {
    let tempData = doc.data();
    data.push({
      uid: doc.id,
      email: tempData["email"],
      confirmed: tempData["confirmed"],
      created_at: new Date(tempData["created_at"]["seconds"] * 1000),
      is_cancel: tempData["is_cancel"],
      price: tempData["price"],
      ticket: tempData["ticket"],
      ticket_bonus: tempData["ticket_bonus"],
      user_uid: tempData["user_uid"],
      phone: tempData["phone"],
      name: tempData["name"],
    });
  });
  return data;
};
