import { collection, getDocs, orderBy, query } from "firebase/firestore";
import FirebaseConfig from "../../../config/firebase";
import TokenSalePhaseType from "../TokenSalePhaseType";
import { DateTime } from "luxon";
export default async function () {
  const q = query(collection(FirebaseConfig.db, "tokenSalePhases"), orderBy("start_at", "asc"));
  const data: Array<TokenSalePhaseType> = [];
  let querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    let tempData = doc.data();
    data.push({
      uid: doc.id,
      title: tempData.title,
      type: tempData.type,
      start_at: DateTime.fromMillis(
        tempData.start_at.seconds * 1000
      ).toJSDate(),
      end_at: DateTime.fromMillis(tempData.end_at.seconds * 1000).toJSDate(),
      price: tempData.price,
      amount: tempData.amount,
      sold: tempData.sold,
      max_purchase: tempData.max_purchase,
      participated_user: tempData.participated_user,
      visited_user: tempData.visited_user,
    });
  });
  return data;
}
