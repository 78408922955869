import { useState } from "react";
import PopUpAddAdmin from "./components/PopUpAddAdmin";
import AdminList from "./components/AdminList";
import { Alert, Snackbar } from "@mui/material";
import React from "react";
import { useAppSelector } from "../../hook";

export default function() {
  const [showPopUpAdd, setShowPopUpAdd] = useState(false);
  const [open, setOpen] = useState(false);

  const handleClick = () => setOpen(true);

  const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') return;
    setOpen(false);
  };

  const permissions = useAppSelector((states) => states.userPermissions.permissions);
  
  if (!permissions.includes("all")) return(
    <div className="w-full flex flex-col justify-center">
      <p className="text-2xl text-center my-auto animate-bounce">You don't have permission to access this feature</p>
    </div>
  )

  return(
    <div className="w-full h-full p-3">
      {showPopUpAdd && <PopUpAddAdmin showSnackBar={handleClick} setShowPopUpAdd={setShowPopUpAdd} />}
      <p className="font-bold text-4xl text-center">Admin Management</p>
      <button onClick={() => setShowPopUpAdd(true)} className="mt-2 w-full px-3 py-2 rounded-md bg-blue-500 text-white">Add / update new low level admin</button>
      <AdminList />
      <Snackbar anchorOrigin={{vertical: 'bottom', horizontal: 'center'}} open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>Operation Success</Alert>
      </Snackbar>
    </div>
  )
}