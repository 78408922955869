import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { v4 as uuidv4 } from "uuid";
import {
  doc,
  updateDoc,
} from "firebase/firestore";
import FirebaseConfig from "../../config/firebase";

export default async function ({ uid, name, description, image, background_cover, logo }: { uid: string, name?: string, description?: string, image?: Array<File>, logo?: File, background_cover?: File }) {
  var re = /(?:\.([^.]+))?$/;
  let imageUrlArray: String[] = [];
  let logoUrl: String = "";
  let bgCoverUrl: String = "";
  if (image) {
    for (let index = 0; index < image.length; index++) {
      let splitFilename = re.exec(image[index].name);
      let ext = splitFilename ? splitFilename[1] : "";
      const imagesRef = ref(FirebaseConfig.storage, `products/${uuidv4()}.${ext}`);
      const uploadTask = await uploadBytes(imagesRef, image[index]);
      const imageUrl = await getDownloadURL(uploadTask.ref);
      imageUrlArray.push(imageUrl)
    }
  }

  if (logo) {
    let logoSplitFilename = re.exec(logo.name);
    let logoExt = logoSplitFilename ? logoSplitFilename[1] : "";
    const logoRef = ref(FirebaseConfig.storage, `products/${uuidv4()}.${logoExt}`);
    const uploadTaskLogo = await uploadBytes(logoRef, logo);
    logoUrl = await getDownloadURL(uploadTaskLogo.ref);
  }

  if (background_cover) {
    let bgCoverSplitFilename = re.exec(background_cover.name);
    let bgCoverExt = bgCoverSplitFilename ? bgCoverSplitFilename[1] : "";
    const bgCoverRef = ref(FirebaseConfig.storage, `products/${uuidv4()}.${bgCoverExt}`);
    const uploadTaskBgCover = await uploadBytes(bgCoverRef, background_cover);
    bgCoverUrl = await getDownloadURL(uploadTaskBgCover.ref);
  }

  let updatedData: EditProductType = {
    name: name || "",
    description: description || ""
  }
  if (bgCoverUrl) {
    updatedData = {
      ...updatedData,
      background_cover: bgCoverUrl
    }
  }
  if (imageUrlArray.length > 0) {
    updatedData = {
      ...updatedData,
      image: imageUrlArray,
    }
  }
  if (logoUrl) {
    updatedData = {
      ...updatedData,
      logo: logoUrl
    }
  }
  await updateDoc(doc(FirebaseConfig.db, "products", uid), updatedData);
};

type EditProductType = {
  name: string,
  description: string,
  image?: Array<String>,
  logo?: String,
  background_cover?: String
}