import { useMutation, useQuery } from "@tanstack/react-query"
import getTokenSalePhase from "./api/getTokenSalePhase";
import TokenSalePhaseType from "./TokenSalePhaseType";
import { HiTrash } from "react-icons/hi";
import { useNavigate } from "react-router-dom";
import deleteTokenSalePhase from "./api/deleteTokenSalePhase";

export default function () {
  const { isLoading, error, data } = useQuery<TokenSalePhaseType[], Error>(["TokenSalePhase"], getTokenSalePhase)
  const navigate = useNavigate();
  const mutation = useMutation({
    mutationKey: ["test"],
    mutationFn: async ({ uid }: { uid: string }) => {
      await deleteTokenSalePhase(uid)
    }
  })
  const handleDelete = (uid: string) => {
    const confirmation = confirm("Are you sure you want to delete");
    if (!confirmation) return;
    mutation.mutate({ uid: uid })
  }
  return (<>
    {isLoading && "Loading..."}
    {error && "Error loading: " + error.message}
    {data && < table className="w-full border" >
      <thead className=" border ">
        <tr className=" bg-blue-300">
          <th>Title</th>
          <th>Type</th>
          <th>Started At</th>
          <th>End At</th>
          <th>Price</th>
          <th>Amount</th>
          <th>Max Purchase</th>
          <th>Sold</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody className="">
        {data.map((item, index) => (
          <tr className={`${index % 2 == 1 && " bg-gray-100"} hover:bg-gray-300 cursor-pointer`} >
            <td onClick={() => navigate("edit", { state: { ...item } })}>{item.title}</td>
            <td onClick={() => navigate("edit", { state: { ...item } })}>{item.type}</td>
            <td onClick={() => navigate("edit", { state: { ...item } })}>{item.start_at.toString()}</td>
            <td onClick={() => navigate("edit", { state: { ...item } })}>{item.end_at.toString()}</td>
            <td onClick={() => navigate("edit", { state: { ...item } })}>{(item.price || 0).toLocaleString()}</td>
            <td onClick={() => navigate("edit", { state: { ...item } })}>{item.amount.toLocaleString()}</td>
            <td onClick={() => navigate("edit", { state: { ...item } })}>{(item.max_purchase || 0).toLocaleString()}</td>
            <td onClick={() => navigate("edit", { state: { ...item } })}>{(item.sold || 0).toLocaleString()}</td>
            <td><HiTrash color="red" onClick={() => handleDelete(item.uid!)} /></td>
          </tr>
        ))}
      </tbody>
    </table >
    }
  </>)

}