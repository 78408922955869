import React, { useState } from 'react'
import { IoClose } from 'react-icons/io5';
import Button from '@mui/material/Button';
import { Checkbox, FormControlLabel, FormGroup } from '@mui/material';
import useCustomMutation from '../../../utils/useCustomMutation';
import addAdmin from '../services/addAdmin';
import { useQueryClient } from '@tanstack/react-query';

function PopUpAddAdmin({ showSnackBar, setShowPopUpAdd }: { showSnackBar: () => void, setShowPopUpAdd: React.Dispatch<React.SetStateAction<boolean>> }) {
  const queryClient = useQueryClient();
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [permissions, setPermissions] = useState<Array<string>>([]);

  const { mutate } = useCustomMutation(addAdmin, {
    onSuccess: () => {
      queryClient.invalidateQueries(["admin_list"]);
      showSnackBar();
      setShowPopUpAdd(false);
    },
    onError: (err: string) => {
      setError(err.toString());
    }
  });

  function validateEmail(email: string) {
    return String(email)
      .toLowerCase()
      .match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
  };

  function handleCbPermissions(permission: string, checked: boolean) {
    if (checked) setPermissions((prev) => [...prev, permission]);
    else setPermissions((prev) => prev.filter(e => e !== permission));
  }

  function handleAddAdmin() {
    setError("");
    if (!validateEmail(email)) setError("Email not valid");
    else mutate({ email, permissions })
  }

  return (
    <div className="fixed flex justify-center z-50 h-full inset-0 bg-black bg-opacity-60">
      <div className="relative p-5 min-w-[400px] h-fit m-auto rounded-2xl bg-white">
        <IoClose onClick={() => setShowPopUpAdd(false)} className="cursor-pointer text-2xl absolute top-3 right-3" />
        <p className="mt-2 mb-0 text-lg font-medium">Enter user email</p>
        <input type="email" value={email} onChange={(e) => setEmail(e.currentTarget.value)} placeholder='sumitro@gmail.com' className="w-full mt-2 rounded-lg" />
        <p className="mt-3 mb-1 font-medium">Permissions:</p>
        <div className="border shadow-md rounded-lg mb-3 p-2">
          <FormGroup>
            <FormControlLabel control={<Checkbox onChange={(e) => handleCbPermissions("payment", e.currentTarget.checked)} />} label="Payment" />
            <FormControlLabel control={<Checkbox onChange={(e) => handleCbPermissions("token_payment", e.currentTarget.checked)} />} label="Token Payment" />
            <FormControlLabel control={<Checkbox onChange={(e) => handleCbPermissions("deposit", e.currentTarget.checked)} />} label="Deposit" />
            <FormControlLabel control={<Checkbox onChange={(e) => handleCbPermissions("product", e.currentTarget.checked)} />} label="Product" />
            <FormControlLabel control={<Checkbox onChange={(e) => handleCbPermissions("token_sale_phase", e.currentTarget.checked)} />} label="Token Sale Phase" />
            <FormControlLabel control={<Checkbox onChange={(e) => handleCbPermissions("social_media_news", e.currentTarget.checked)} />} label="Social Media News" />
            <FormControlLabel control={<Checkbox onChange={(e) => handleCbPermissions("voucher", e.currentTarget.checked)} />} label="Voucher" />
          </FormGroup>
        </div>
        {error && <p className="text-sm font-medium py-1 px-2 w-fit rounded-md bg-red-200 text-red-500">{error}</p>}
        <Button onClick={handleAddAdmin} className="w-full mt-3" variant="outlined">Add Admin</Button>
      </div>
    </div>
  )
}

export default PopUpAddAdmin;
