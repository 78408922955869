import { Button } from 'flowbite-react'
import React from 'react'
import { DepositType } from '../deposit/DepositType'

function ConfirmedDepositPaymentItem({ data, setPopUpConfirmation }: { data: DepositType, setPopUpConfirmation: React.Dispatch<React.SetStateAction<boolean>> }) {
  return (
    <div className="mt-4 border-2 shadow-lg rounded-xl p-4">
      <div className="max-w-md grid grid-cols-2">
        <p>Transaction hash:</p>
        <p className="font-medium">{data.transaction_hash}</p>
        <p>Email:</p>
        <p className="font-medium">{data.email}</p>
        <p>USDT Amount:</p>
        <p className="font-medium">{data.amount.toLocaleString()}</p>
        <p>Created at:</p>
        <p className="font-medium">{data.created_at.toDateString()}</p>
      </div>
      <Button onClick={() => setPopUpConfirmation(true)} className="mt-4 bg-red-600 hover:bg-red-500">Revert Payment</Button>
    </div>
  )
}

export default ConfirmedDepositPaymentItem