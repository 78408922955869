import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface UserPermissionsState {
  permissions: Array<string>;
}

const initialState: UserPermissionsState = {
  permissions: [],
};

export const userPermissionsSlice = createSlice({
  name: "userPermissions",
  initialState,
  reducers: {
    changeUserPermissionsState: (state, action: PayloadAction<UserPermissionsState>) => {
      state.permissions = action.payload.permissions;
    },
  },
});

export const {
  changeUserPermissionsState,
} = userPermissionsSlice.actions;
export default userPermissionsSlice.reducer;
