import { useQuery } from "@tanstack/react-query";
import { getUncompleteClaim } from "./apiClaim";
import ClaimItem from "./ClaimItem";

export default function () {
  const query = useQuery(["unCompletedClaim"], getUncompleteClaim)
  return <div>
    <div className="text-3xl">Claim</div>
    <div className="flex flex-col gap-y-2">
      {query.data?.map((item, index) => <ClaimItem key={index} claim_item={item} />)}
    </div>
  </div>;
}
