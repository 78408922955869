import { useQueryClient } from "@tanstack/react-query"
import adminDepositConfirmationService from "./adminDepositConfirmationService"
import { DepositType } from "./DepositType"
import { FormEvent, Fragment, useEffect, useState } from "react"
import { Button, Modal } from "flowbite-react"
import { FaCheck } from "react-icons/fa"
import useCustomMutation from "../../utils/useCustomMutation"

export function ConfirmDepositModal({ selected_payment, refetch }: { selected_payment: DepositType, refetch: () => void }) {  
  const mutation = useCustomMutation((selected_payment: DepositType) => adminDepositConfirmationService(selected_payment, "confirm"))

  const [show, setShow] = useState(false);
  const [error, setError] = useState("");
  const onClick = () => setShow(true);
  const handleClose = () => setShow(false);

  const handlePaymentConfirmation = () => {
    if (!selected_payment.amount) {
      setError("USDT Amount invalid");
      return;
    }
    mutation.mutate(selected_payment, {
      onSuccess: () => {
        refetch();
        setShow(false)
      }
    })
  }

  return <Fragment>
    <Button className="w-full" color={"success"} onClick={onClick}><FaCheck className="mr-1" size={14} color={"white"} />Confirm</Button>
    <Modal
      show={show}
      onClose={handleClose}
    >
      <Modal.Header>
        Confirmation
      </Modal.Header>
      <Modal.Body>
        <p>The user will receive: {selected_payment.amount || 0} USDT</p>
        {error && <p className="font-medium text-red-500">{error}</p>}
        <p></p>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={handlePaymentConfirmation} disabled={mutation.isLoading}>
          I accept
        </Button>
        <Button
          color="gray"
          onClick={handleClose}
          disabled={mutation.isLoading}
        >
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  </Fragment>
}

export function RejectDepositModal({ selected_payment, refetch }: { selected_payment: DepositType, refetch: () => void }) {
  const queryClient = useQueryClient();
  const [reason, setReason] = useState("")
  const mutation = useCustomMutation(({ selected_payment, reason }: { selected_payment: DepositType, reason: string }) => adminDepositConfirmationService(selected_payment, "reject", reason))
  const handleOnChange = (e: FormEvent<HTMLTextAreaElement>) => {
    setReason(e.currentTarget.value)
  }
  const [show, setShow] = useState(false)
  const onClick = () => {
    setShow(!show)
  }
  const handlePaymentRejection = () => {
    mutation.mutate({ selected_payment, reason }, {
      onSuccess: () => refetch()
    })
    setShow(false)
  }
  const handleClose = () => {
    setShow(false)
  }
  return <Fragment>
    <Button className="w-full" color={"failure"} onClick={onClick}><FaCheck className="mr-1" size={14} color={"white"} />Reject</Button>
    <Modal
      show={show}
      onClose={handleClose}
    >
      <Modal.Header>
        Reject
      </Modal.Header>
      <Modal.Body>
        <div>Are you sure to reject this deposit</div>

        <form>
          <div className="relative z-0 w-full mb-6 group mt-2">
            <textarea onBlur={handleOnChange} name="Reason" id="Reason" className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " required defaultValue={reason} />
            <label
              htmlFor="Reason"
              className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
            >
              Reason address
            </label>
          </div>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={handlePaymentRejection} color={"failure"} disabled={mutation.isLoading}>
          Reject
        </Button>
        <Button
          color="gray"
          onClick={handleClose}
          disabled={mutation.isLoading}
        >
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  </Fragment>
}