import { useQuery } from '@tanstack/react-query';
import React from 'react'
import { AiFillGold } from "react-icons/ai";
import formatPrice from '../../../utils/priceFormatter';

function ProductCard(product: any) {
  function getFormattedTime(time: string) {
    const formattedTime = new Date(time).toLocaleDateString('en-US', {
      weekday: 'long',
      day: '2-digit',
      month: 'long',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hourCycle: 'h12'
    });
    return formattedTime
  }

  return (
    <div className="shadow-lg p-4 w-full lg:w-[282px] rounded-3xl">
      <div className="flex justify-between">
        <div className="mt-2 ml-4 w-8 h-8 rounded-full bg-[#FFF6BF] flex items-center">
          <AiFillGold className="text-[#FF9F06] w-4 h-4 mx-auto" />
        </div>
        <div className="flex flex-col gap-1">
          <p className="text-[#ABABAB] text-xs font-medium">Joined By:</p>
          <p className="text-[#ABABAB] text-lg font-bold text-transparent bg-clip-text bg-gradient-to-r from-[#F27121] to-[#8A2387]">
            {product.joined_user ? Object.keys(product.joined_user).length : 0} User</p>
        </div>
      </div>
      <div className='mt-4 mx-4 lg:mx-auto rounded-3xl h-[320px] lg:h-[250px] flex justify-center items-center overflow-hidden'>
        <img className='w-full h-full mx-auto object-cover rounded-3xl' src={product.image} />
      </div>
      <p className="mt-4 text-base mx-4 font-bold">{product.name}</p>
      {product.worth_price && <p className="mt-1 text-xs mx-4 font-medium text-[#ABABAB]">Worth: {formatPrice(product.worth_price)}</p>}
      <div className="mt-2 mx-4">
        <p className="font-medium">Collected Amount: {product.collected_amount}</p>
        <p className="font-medium">Anonymous visit: {product.anonymous_visit}</p>
        <p className="font-medium">Visited user: {product.visited_user ? Object.keys(product.visited_user).length : 0}</p>
      </div>
      <div className="flex gap-1 pl-4 ">
        <p className="text-sm font-medium">Start: </p>
        <p className="my-auto text-xs font-medium text-[#ABABAB]">{getFormattedTime(product.start_time)}</p>
      </div>
      <div className="flex gap-1 pl-4 ">
        <p className="text-sm font-medium">End: </p>
        <p className="my-auto text-xs font-medium text-[#ABABAB]">{getFormattedTime(product.end_time)}</p>
      </div>
      {product.winner_email && (
      <div className="flex gap-1 pl-4">
        <p className="font-medium">Winner: </p>
        <p className="font-bold text-transparent bg-clip-text bg-gradient-to-r from-[#F27121] to-[#8A2387]">{product.winner_email}</p>
      </div>)}
      <div className="mt-2 h-[1px] w-full bg-[#F3F3F3]" />
      <div className="flex justify-between mx-4 mt-2">
        <div className="flex flex-col">
          <p className="text-xs font-medium text-[#ABABAB]">Lowest Bid</p>
          <p className="mt-1 text-sm font-bold">{formatPrice(product.lowest_price)}</p>
        </div>
        <div className="flex flex-col">
          <p className="text-xs font-medium text-[#ABABAB]">Highest Bid</p>
          <p className="mt-1 text-sm font-bold">{formatPrice(product.highest_price)}</p>
        </div>
      </div>
    </div>
  )
}

export default ProductCard