import { useAppSelector } from "../../hook";
import PaymentList from "./PaymentList";

export default function () {
  const permissions = useAppSelector((states) => states.userPermissions.permissions);
  
  if (!permissions.includes("payment") && !permissions.includes("all")) return(
    <div className="w-full flex flex-col justify-center">
      <p className="text-2xl text-center my-auto animate-bounce">You don't have permission to access this feature</p>
    </div>
  )

  return (
    <div className="w-full mx-2">
      <div className="text-lg font-bold mt-2">Latest Payment</div>
      <PaymentList />
    </div>
  );
}
