import { useQuery } from "@tanstack/react-query";
import React, { Fragment, useEffect } from "react";
import getTokenPaymentService from "./services/getTokenPaymentService";
import TokenPaymentCard from "./components/TokenPaymentCard";
import { useAppSelector } from "../../hook";

function AdminTokenPayment() {
  const { data } = useQuery(["unconfirmed token payment"], () =>
    getTokenPaymentService()
  );
  const permissions = useAppSelector((states) => states.userPermissions.permissions);
  
  if (!permissions.includes("token_payment") && !permissions.includes("all")) return(
    <div>
      <p className="">You don't have permission to access this feature</p>
    </div>
  )

  return (
    <div className="w-full mx-2">
      <p className="text-2xl font-bold mt-2">Latest Token Payment</p>
      <div className="flex flex-col gap-y-2">
        {data?.map((item, index) => {
          return (
            <Fragment key={index}>
              <TokenPaymentCard item={item} key={index} />
            </Fragment>
          );
        })}
      </div>
    </div>
  );
}

export default AdminTokenPayment;
