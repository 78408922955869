import { Button } from '@mui/material';
import React, { useState } from 'react'
import { IoClose } from 'react-icons/io5';
import useCustomMutation from '../../../utils/useCustomMutation';
import { useQueryClient } from '@tanstack/react-query';
import deleteAdmin from '../services/deleteAdmin';

function PopUpDeleteAdmin({ email, setShowPopUpDelete }: { email: string, setShowPopUpDelete: React.Dispatch<React.SetStateAction<boolean>> }) {
  const queryClient = useQueryClient();
  const [error, setError] = useState("");
  
  const { mutate } = useCustomMutation(deleteAdmin, {
    onSuccess: () => {
      queryClient.invalidateQueries(["admin_list"]);
      setShowPopUpDelete(false);
    },
    onError: (err: string) => {
      setError(err.toString());
    }
  });
  
  return (
    <div className="fixed flex justify-center z-50 h-full inset-0 bg-black bg-opacity-60">
      <div className="relative p-5 min-w-[400px] h-fit m-auto rounded-2xl bg-white">
        <IoClose onClick={() => setShowPopUpDelete(false)} className="cursor-pointer text-xl absolute top-3 right-3" />
        <p className="font-medium text-center mt-3">Are you sure you want to delete this admin</p>
        {error && <p className="mx-auto mt-2 text-sm font-medium py-1 px-2 w-fit rounded-md bg-red-200 text-red-500">{error}</p>}
        <div className="flex gap-4 mt-5 w-fit mx-auto">
          <Button onClick={() => mutate({ email })} variant='contained' color='error'>Delete</Button>
          <Button onClick={() => setShowPopUpDelete(false)} className="bg-gray-400" variant='contained'>Cancel</Button>
        </div>
      </div>
    </div>
  )
}

export default PopUpDeleteAdmin;
