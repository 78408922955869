import { useAppSelector } from "../../hook";
import DepositList from "./DepositList";

export default function () {
    const permissions = useAppSelector((states) => states.userPermissions.permissions);
  
    if (!permissions.includes("deposit") && !permissions.includes("all")) return(
        <div className="w-full flex flex-col justify-center">
        <p className="text-2xl text-center my-auto animate-bounce">You don't have permission to access this feature</p>
        </div>
    )

    return (
        <div className="w-full mx-2">
            <DepositList />
        </div>
    );
}
