export default function formatPrice(number: number | undefined) {
  if (number === 0) return "Rp 0";
  if (!number) return 0;
  return new Intl.NumberFormat("id-ID", {
    style: "currency",
    currency: "IDR",
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(number);
}
