import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { v4 as uuidv4 } from "uuid";
import {
  addDoc,
  collection,
  doc,
  getDocs,
  query,
  orderBy,
  serverTimestamp,
  deleteDoc,
} from "firebase/firestore";
import FirebaseConfig from "../../config/firebase";
import { Product } from "../../types/ProductType";

export type ProductCreate = {
  name: string;
  description: string;
  usdt_amount: number;
  worth_price: number;
  lowest_price: number;
  highest_price: number;
  image: Array<File>;
  logo: File | null;
  background_cover: File | null;
  start_time: Date;
  end_time: Date;
  is_calculated: boolean;
  is_extended: boolean;
};
export type ProductCreateInput = {
  name: string;
  description: string;
  usdt_amount: number;
  worth_price: number;
  lowest_price: number;
  highest_price: number;
  image: Array<File>;
  logo: File;
  background_cover: File;
  start_time: Date;
  end_time: Date;
  is_calculated: boolean;
  is_extended: boolean;
};

export const createProduct = async (newProduct: ProductCreateInput) => {
  // Create a child reference
  var re = /(?:\.([^.]+))?$/;
  let imageUrlArray: String[] = [];
  for (let index = 0; index < newProduct.image.length; index++) {
    let splitFilename = re.exec(newProduct.image[index].name);
    let ext = splitFilename ? splitFilename[1] : "";
    const imagesRef = ref(FirebaseConfig.storage, `products/${uuidv4()}.${ext}`);
    const uploadTask = await uploadBytes(imagesRef, newProduct.image[index]);
    const imageUrl = await getDownloadURL(uploadTask.ref);
    imageUrlArray.push(imageUrl)
  }

  let logoSplitFilename = re.exec(newProduct.logo.name);
  let logoExt = logoSplitFilename ? logoSplitFilename[1] : "";
  const logoRef = ref(FirebaseConfig.storage, `products/${uuidv4()}.${logoExt}`);
  const uploadTaskLogo = await uploadBytes(logoRef, newProduct.logo);
  const logoUrl = await getDownloadURL(uploadTaskLogo.ref);

  let bgCoverSplitFilename = re.exec(newProduct.background_cover.name);
  let bgCoverExt = bgCoverSplitFilename ? bgCoverSplitFilename[1] : "";
  const bgCoverRef = ref(FirebaseConfig.storage, `products/${uuidv4()}.${bgCoverExt}`);
  const uploadTaskBgCover = await uploadBytes(bgCoverRef, newProduct.background_cover);
  const bgCoverUrl = await getDownloadURL(uploadTaskBgCover.ref);

  await addDoc(collection(FirebaseConfig.db, "products"), {
    name: newProduct.name,
    description: newProduct.description,
    usdt_amount: parseFloat(newProduct.usdt_amount.toString()),
    worth_price: newProduct.worth_price,
    lowest_price: newProduct.lowest_price,
    highest_price: newProduct.highest_price,
    image: imageUrlArray,
    logo: logoUrl,
    background_cover: bgCoverUrl,
    start_time: newProduct.start_time,
    end_time: newProduct.end_time,
    created_at: serverTimestamp(),
    is_calculated: false,
    is_extended: false,
  });

  return true;
};

export const getProducts = async () => {
  const q = query(
    collection(FirebaseConfig.db, "products"),
    orderBy("created_at", "desc")
  );
  let data: Array<Product> = [];
  let querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    let tempData = doc.data();
    data.push({
      uid: doc.id,
      name: tempData.name,
      description: tempData.description,
      usdt_amount: tempData.usdt_amount,
      worth_price: tempData.worth_price,
      lowest_price: tempData.lowest_price,
      highest_price: tempData.highest_price,
      image: tempData.image,
      logo: tempData.logo,
      background_cover: tempData.background_cover,
      created_at: new Date(tempData.created_at.seconds * 1000),
      start_time: new Date(tempData.start_time.seconds * 1000),
      end_time: new Date(tempData.end_time.seconds * 1000),
    });
  });
  return data;
};

export const deleteProduct = async (uid: string) => {
  await deleteDoc(doc(FirebaseConfig.db, "products", uid));
};

