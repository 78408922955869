import { Button } from 'flowbite-react';
import React, { useState } from 'react'
import PopUpCreateNews from './PopUpCreateNews';
import { useQuery } from '@tanstack/react-query';
import getNewsService from './services/getNewsService';
import NewsItem from './NewsItem';
import { useAppSelector } from '../../hook';

function SocialMediaNews() {
  const [popUpSocialMedia, setPopUpSocialMedia] = useState("");
  const { data } = useQuery(["social media news"], () => getNewsService());
  
  const permissions = useAppSelector((states) => states.userPermissions.permissions);
  
  if (!permissions.includes("social_media_news") && !permissions.includes("all")) return(
    <div className="w-full flex flex-col justify-center">
      <p className="text-2xl text-center my-auto animate-bounce">You don't have permission to access this feature</p>
    </div>
  )

  return (
    <div className="w-full mx-2 flex flex-col gap-y-2">
      {popUpSocialMedia === "create" && <PopUpCreateNews setPopUpSocialMedia={setPopUpSocialMedia} />}
      <Button onClick={() => setPopUpSocialMedia("create")}>Create</Button>

      <div>
        <p className="font-bold text-xl">Social Media News List</p>
        <div className="flex flex-wrap gap-5">
          {data?.map((e, index) => <NewsItem data={e} popUpSocialMedia={popUpSocialMedia} setPopUpSocialMedia={setPopUpSocialMedia} key={index} />)}
        </div>
      </div>
    </div>
  )
}

export default SocialMediaNews;
