import { collection, addDoc, serverTimestamp } from "firebase/firestore";
import { VoucherType } from "./VoucherType";
import FirebaseConfig from "../../config/firebase";

export default async (voucher: VoucherType) => {
  await addDoc(collection(FirebaseConfig.db, "vouchers"), {
    ...voucher,
    created_at: serverTimestamp(), //
  });
  return true;
};
