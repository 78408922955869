import {
  collection,
  getDocs,
  orderBy,
  query,
  where,
  limit,
  startAfter,
  Timestamp,
} from "firebase/firestore";
import { VoucherType } from "./VoucherType";
import FirebaseConfig from "../../config/firebase";

export default async (code: string) => {
  let q = query(
    collection(FirebaseConfig.db, "vouchers"),
    where("code", "==", code),
    limit(1)
  );
  const querySnapshot = await getDocs(q);
  return querySnapshot.size > 0;
};
