import { Button } from "flowbite-react";
import TokenSalePhaseList from "./TokenSalePhaseList";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../hook";

export default function () {
  const navigate = useNavigate();
  const permissions = useAppSelector((states) => states.userPermissions.permissions);
  
  if (!permissions.includes("token_sale_phase") && !permissions.includes("all")) return(
    <div className="w-full flex flex-col justify-center">
      <p className="text-2xl text-center my-auto animate-bounce">You don't have permission to access this feature</p>
    </div>
  )
  
  return (
    <div className="w-full mx-2 flex flex-col gap-y-2">
      <Button onClick={() => navigate("create")}>Create</Button>
      <TokenSalePhaseList />
    </div>
  )
}