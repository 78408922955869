import { collection, doc, endAt, endBefore, getDoc, getDocs, limit, limitToLast, orderBy, query, startAfter, where } from "firebase/firestore";
import FirebaseConfig from "../../../config/firebase";
import { TokenPaymentType } from "../../token/TokenPaymentType";

export async function getHistoryTokenFirstPage(phaseUid: string) {
  let firstPageQuery = query(collection(FirebaseConfig.db, "token_payments"), where("phase_uid", "==", phaseUid), 
  where("confirmed", "==", true), where("is_cancel", "==", false), limit(10), orderBy("created_at", "desc"));

  const docSnapshots = await getDocs(firstPageQuery);
  const result: Array<TokenPaymentType> = [];

  if (docSnapshots.docs.length <= 0) return {result, firstVisibleId: "", lastVisibleId: ""};

  const firstVisibleId = docSnapshots.docs[0].id;
  const lastVisibleId = docSnapshots.docs[docSnapshots.docs.length - 1].id;

  docSnapshots.forEach(docSnap => {
    const doc: any = docSnap.data();
    result.push({ ...doc, created_at: doc.created_at.toDate().toString() })
  });
  
  return { result, firstVisibleId, lastVisibleId };
}

export async function getHistoryTokenNextPage(phaseUid: string, lastDocId: string) {
  const lastDocSnap = await getDoc(doc(FirebaseConfig.db, `token_payments/${lastDocId}`));

  let pageQuery = query(collection(FirebaseConfig.db, "token_payments"), where("phase_uid", "==", phaseUid), 
  where("confirmed", "==", true), where("is_cancel", "==", false), limit(10), orderBy("created_at", "desc"), startAfter(lastDocSnap));

  const docSnapshots = await getDocs(pageQuery);
  if (docSnapshots.docs.length <= 0) return false;

  const firstVisibleId = docSnapshots.docs[0].id;
  const lastVisibleId = docSnapshots.docs[docSnapshots.docs.length - 1].id;

  const result: Array<TokenPaymentType> = [];
  docSnapshots.forEach(docSnap => {
    const doc: any = docSnap.data();
    result.push({ ...doc, created_at: doc.created_at.toDate().toString() })
  });
  
  return { result, firstVisibleId, lastVisibleId};
}

export async function getHistoryTokenPrevPage(phaseUid: string, firstDocId: string) {
  const firstDocSnap = await getDoc(doc(FirebaseConfig.db, `token_payments/${firstDocId}`));

  let pageQuery = query(collection(FirebaseConfig.db, "token_payments"), where("phase_uid", "==", phaseUid), 
  where("confirmed", "==", true), where("is_cancel", "==", false), limitToLast(10), orderBy("created_at", "desc"), endBefore(firstDocSnap));

  const docSnapshots = await getDocs(pageQuery);
  if (docSnapshots.docs.length <= 0) return false;

  const firstVisibleId = docSnapshots.docs[0].id;
  const lastVisibleId = docSnapshots.docs[docSnapshots.docs.length - 1].id;

  const result: Array<TokenPaymentType> = [];
  docSnapshots.forEach(docSnap => {
    const doc: any = docSnap.data();
    result.push({ ...doc, created_at: doc.created_at.toDate().toString() })
  });
  
  return { result, firstVisibleId, lastVisibleId};
}