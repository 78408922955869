import { useMutation } from "@tanstack/react-query"
import { Button, Card } from "flowbite-react"
import deleteVoucher from "../voucher/deleteVoucher"

export default function ({ item, onDelete }: { item: any, onDelete: () => void }) {
  const itemData = Object.keys(item).map(key => {
    return <div className="flex gap-x-4">
      <div className="flex-1 w-fit">{key}</div>
      {
        key === 'start_from' || key === "valid_until" ?
          <div className="flex-1 font-bold">{((item[key]) as Date).toLocaleString()}</div>
          :
          <div className="flex-1 font-bold">{JSON.stringify(item[key])}</div>
      }

    </div>
  })
  return < div className="w-full mr-2" key={item.uid}>
    <Card className="w-full">
      {itemData}
      <Button color={"failure"} onClick={onDelete}>Delete</Button>
    </Card >
  </div >
}