import {
} from "firebase/firestore";
import { httpsCallable } from "firebase/functions";
import FirebaseConfig from "../../../config/firebase";

export default async function ({ email }: { email: string }) {
  const functions = FirebaseConfig.functions;
  const addBlockUser = httpsCallable(functions, "addBlockUser");
  const res = await addBlockUser({
    email,
  });

  return res;
}
