import { Button } from '@mui/material';
import React, { useState } from 'react'
import { RiAccountPinCircleFill } from 'react-icons/ri';
import PopUpRemoveBlockedUser from './PopUpRemoveBlockedUser';

function BlockedUserItem({ email }: { email: string }) {
  const [showPopUpRemove, setShowPopUpRemove] = useState(false);

  return (
    <div className="relative rounded-2xl p-4 shadow-md border w-56 h-fit">
      {showPopUpRemove && <PopUpRemoveBlockedUser email={email} setShowPopUp={setShowPopUpRemove} />}
      <RiAccountPinCircleFill className="text-5xl mx-auto" />
      <p className="font-medium mt-2">Email: </p>
      <p className="break-words ">{email}</p>
      <Button onClick={() => setShowPopUpRemove(true)} className="mt-5" variant='contained' color='error'>Delete</Button>
    </div>
  )
}

export default BlockedUserItem;
