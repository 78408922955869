import React, { useState } from 'react'
import PopUpAddBlockUser from './components/PopUpAddBlockUser';
import { Alert, Snackbar } from '@mui/material';
import BlockedUserList from './components/BlockedUserList';

export default function() {
  const [showPopUpAdd, setShowPopUpAdd] = useState(false);
  const [open, setOpen] = useState(false);

  const handleClick = () => setOpen(true);

  const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') return;
    setOpen(false);
  };
  
  return(
    <div className="w-full px-4">
      <p className="font-bold text-4xl text-center">Block User</p>
      <button onClick={() => setShowPopUpAdd(true)} className="mt-2 w-full px-3 py-2 rounded-md bg-blue-500 text-white">Add User to block list</button>
      {showPopUpAdd && <PopUpAddBlockUser showSnackBar={handleClick} setShowPopUp={setShowPopUpAdd} />}
      <BlockedUserList />
      <Snackbar anchorOrigin={{vertical: 'bottom', horizontal: 'center'}} open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>Operation Success</Alert>
      </Snackbar>
    </div>
  )
}