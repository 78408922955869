import { useQuery } from "@tanstack/react-query"
import TokenSalePhaseType from "./features/tokenSalePhase/TokenSalePhaseType"
import getTokenSalePhase from "./features/tokenSalePhase/api/getTokenSalePhase"
import DashboardCard from "./features/dashboard/DashboardCard";

export default function () {
  const { data: saleData } = useQuery<TokenSalePhaseType[], Error>(["TokenSalePhase"], getTokenSalePhase);
  
  return ( 
    <div className="p-4 w-full">
      <p className="text-3xl font-bold">Dashboard</p>
      <p className="my-3">Token Phase Data :</p>
      <div className="flex flex-wrap gap-4">
        {saleData?.map((data, index) => <DashboardCard key={index} data={data} />)}
      </div>
    </div>
  )
}
