import {
} from "firebase/firestore";
import { httpsCallable } from "firebase/functions";
import FirebaseConfig from "../../../config/firebase";

export default async function ({ email }: { email: string }) {
  const functions = FirebaseConfig.functions;
  const removeBlockedUser = httpsCallable(functions, "removeBlockedUser");
  const res = await removeBlockedUser({
    email,
  });

  return res;
}
