import { Button, Dropdown, Label, TextInput } from 'flowbite-react';
import React, { FormEvent, useState } from 'react';
import { BsMedium } from 'react-icons/bs';
import { IoClose, IoLogoDiscord, IoLogoInstagram, IoLogoTwitter } from 'react-icons/io5';
import { RiTelegramFill } from 'react-icons/ri';
import useCustomMutation from '../../utils/useCustomMutation';
import addNewsService from './services/addNewsService';
import { useQueryClient } from '@tanstack/react-query';

function PopUpCreateNews({ setPopUpSocialMedia }: { setPopUpSocialMedia: React.Dispatch<React.SetStateAction<string>> }) {
  const [platform, setPlatform] = useState("Choose platform");
  const [error, setError] = useState("")
  const [input, setInput] = useState({ title: "", link: "" });
  const queryClient = useQueryClient();
  
  const handleChangeInput = (e: FormEvent<HTMLInputElement>) => {
    setInput({ ...input, [e.currentTarget.id]: e.currentTarget.value })
  }

  const { mutate: createNews } = useCustomMutation(addNewsService, {
    onSuccess: () => {
      queryClient.invalidateQueries(["social media news"])
      setPopUpSocialMedia("");
    },
    onError: (e: Error) => {
      setError(e.message);
    }
  })

  return (
    <div className="fixed flex justify-center z-50 h-full inset-0 bg-black bg-opacity-60">
      <div className="relative p-4 min-w-[400px] h-fit m-auto rounded-2xl bg-white">
        <IoClose onClick={() => setPopUpSocialMedia("")} className="absolute text-xl right-5 cursor-pointer" />
        <p className="font-bold text-lg text-center mb-4">Create Social Media News</p>
        <div className="mb-2">
          <Label htmlFor="platform" value="Social Media Platform" />
          <Dropdown label={platform} id="platform">
            <Dropdown.Item onClick={() => setPlatform("Medium")} icon={BsMedium}>Medium</Dropdown.Item>
            <Dropdown.Item onClick={() => setPlatform("Twitter")} icon={IoLogoTwitter}>Twitter</Dropdown.Item>
            <Dropdown.Item onClick={() => setPlatform("Instagram")} icon={IoLogoInstagram}>Instagram</Dropdown.Item>
            <Dropdown.Item onClick={() => setPlatform("Telegram")} icon={RiTelegramFill}>Telegram</Dropdown.Item>
            <Dropdown.Item onClick={() => setPlatform("Discord")} icon={IoLogoDiscord}>Discord</Dropdown.Item>
          </Dropdown>
        </div>

        <div className="mb-2">
          <Label htmlFor="title" value="Title" />
          <TextInput onChange={handleChangeInput} id="title" />
        </div>
        
        <div>
          <Label htmlFor="link" value="Link News" />
          <TextInput onChange={handleChangeInput} id="link" />
        </div>
        {error && error}
        <Button onClick={() => createNews({ platform, title: input.title, link: input.link })} disabled={!(input.link && input.title && platform !== "Choose platform")} 
        className="transition-all mt-6 w-full bg-blue-600 hover:bg-blue-500">Create</Button>
      </div>
    </div>
  )
}

export default PopUpCreateNews;
