import { Button } from 'flowbite-react';
import React, { useState } from 'react'
import { IoClose } from 'react-icons/io5';
import useCustomMutation from '../../utils/useCustomMutation';
import revertPaymentService from './services/revertPaymentService';
import { useQueryClient } from '@tanstack/react-query';
import { DepositType } from '../deposit/DepositType';

function PopUpRevertConfirmation({ data, setPaymentData, setPopUpConfirmation }: { data: DepositType, 
  setPaymentData: React.Dispatch<React.SetStateAction<DepositType | undefined>>, setPopUpConfirmation: React.Dispatch<React.SetStateAction<boolean>> }) {
  const queryClient = useQueryClient();
  const [error, setError] = useState("");

  const { mutate: revertPayment } = useCustomMutation(revertPaymentService, {
    onSuccess: () => {
      queryClient.invalidateQueries(["unconfirmed token payment"])
      setPopUpConfirmation(false);
      if (setPaymentData) setPaymentData(undefined);
    },
    onError: (e: Error) => {
      setError(e.message);
    }
  })

  return (
    <div className="fixed flex justify-center z-50 h-full inset-0 bg-black bg-opacity-60">
      <div className="relative p-4 min-w-[400px] h-fit m-auto rounded-2xl bg-white">
        <IoClose onClick={() => setPopUpConfirmation(false)} className="absolute text-xl right-5 cursor-pointer" />
        <p className="font-bold text-lg text-center mb-4">Revert Confirmation</p>
        <p className="text-center font-medium">Are your sure want to revert this deposit payment ?</p>
        {error && <p className="font-medium text-red-500">{error}</p>}
        <div className="flex mt-3 gap-3">
          <Button onClick={() => revertPayment(data)} className="flex-1">Yes</Button>
          <Button onClick={() => setPopUpConfirmation(false)} className="flex-1 bg-red-600 hover:bg-red-500">No</Button>
        </div>
      </div>
    </div>
  )
}

export default PopUpRevertConfirmation;
