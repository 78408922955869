import { Button } from 'flowbite-react'
import React, { useState } from 'react'
import ConfirmedDepositPaymentItem from './ConfirmedDepositPaymentItem'
import useCustomMutation from '../../utils/useCustomMutation';
import getConfirmedPayment from './services/getConfirmedPayment';
import PopUpRevertConfirmation from './PopUpRevertConfirmation';
import { useAppSelector } from '../../hook';
import { DepositType } from '../deposit/DepositType';

function RevertDepositPayment() {
  const [txHash, setTxHash] = useState("");
  const [errResponse, setErrResponse] = useState("");
  const [paymentData, setPaymentData] = useState<DepositType>();
  const [popUpConfirmation, setPopUpConfirmation] = useState(false);

  const { mutate: getPayment } = useCustomMutation(getConfirmedPayment, {
    onSuccess: (result) => {
      if (result.length <= 0) setErrResponse("Transaction hash not found");
      else if (!result[0].confirmed) setErrResponse("Transaction not confirmed yet");
      else if (result[0].confirmed && result[0].is_cancel) setErrResponse("Transaction has been rejected");
      else if (result[0].confirmed && !result[0].is_cancel) {
        setErrResponse("");
        setPaymentData(result[0]);
      }
    }
  })

  function handleSearchButton() {
    if (txHash) getPayment(txHash);
  }

  function handleKeyPress(event: any) {
    if(event.key === 'Enter' && txHash) getPayment(txHash);
  }

  const permissions = useAppSelector((states) => states.userPermissions.permissions);
  
  if (!permissions.includes("deposit") && !permissions.includes("all")) return(
    <div className="w-full flex flex-col justify-center">
      <p className="text-2xl text-center my-auto animate-bounce">You don't have permission to access this feature</p>
    </div>
  )
  
  return (
    <div className="w-full mx-4">
      <p className="text-2xl font-bold mt-2">Revert Deposit Payment</p>
      <p className="mt-4 font-medium">Search with tx hash</p>
      <div className="flex gap-3 mt-2">
        <input value={txHash} onChange={(e) => setTxHash(e.target.value)} onKeyPress={handleKeyPress}
        className="rounded-xl max-w-[600px] w-full" placeholder="Ex: 0xa4655ca01896ed22cde0x..." type="text" />
        <Button onClick={handleSearchButton} disabled={!txHash}>Search</Button>
      </div>
      {paymentData ? <ConfirmedDepositPaymentItem setPopUpConfirmation={setPopUpConfirmation} data={paymentData} /> : <p className="font-medium text-red-500">{errResponse}</p>}
      {(popUpConfirmation && paymentData) && <PopUpRevertConfirmation data={paymentData} setPaymentData={setPaymentData} setPopUpConfirmation={setPopUpConfirmation} />}
    </div>
  )
}

export default RevertDepositPayment