import {
  collection,
  getDocs,
  orderBy,
  query,
} from "firebase/firestore";
import { SocialMediaNewsType } from "../SocialMediaNewsType";
import FirebaseConfig from "../../../config/firebase";

export default async () => {
  let q = query(
    collection(FirebaseConfig.db, "social_media_news"), orderBy("created_at", "asc"));
  const querySnapshot = await getDocs(q);
  const data: Array<SocialMediaNewsType> = [];
  querySnapshot.forEach((doc) => {
    let tempData = doc.data();
    data.push({
      doc_uid: doc.id,
      link: tempData["link"],
      title: tempData["title"],
      platform: tempData["platform"],
      created_at: new Date(tempData["created_at"]["seconds"] * 1000),
    });
  });
  return data;
};
