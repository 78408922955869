import React from 'react'
import getAuctionData from './services/getAuctionData';
import { useQuery } from '@tanstack/react-query';
import ProductCard from './components/ProductCard';

export default function () {
  const { data } = useQuery(["auction_data"], getAuctionData);
  return (
    <div className="w-full">
      <p className="text-4xl font-bold text-center">Auction Data</p>
      <div className="flex flex-wrap gap-4">
        {data?.map((e, index) => <ProductCard {...e} key={index} />)}
      </div>
    </div>
  )
}
