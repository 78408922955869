import React from 'react'
import TokenSalePhaseType from '../tokenSalePhase/TokenSalePhaseType';
import { AiFillGold } from 'react-icons/ai';

function DashboardCard({ data }: { data: TokenSalePhaseType }) {
  function capitalizeFirstLetter(value: string) {
    return value.charAt(0).toUpperCase() + value.slice(1);
  }

  function getFormattedTime(time: string) {
    const formattedTime = new Date(time).toLocaleDateString('en-US', {
      weekday: 'long',
      day: '2-digit',
      month: 'long',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hourCycle: 'h12'
    });
    return formattedTime
  }

  return (
    <div className="shadow-lg p-4 w-full lg:w-[282px] rounded-3xl">
      <div className="mt-2 ml-4 w-8 h-8 rounded-full bg-[#FFF6BF] flex items-center">
        <AiFillGold className="text-[#FF9F06] w-4 h-4 mx-auto" />
      </div>
      <div className='mt-4 mx-4 lg:mx-auto rounded-3xl h-[320px] lg:h-[250px] flex justify-center items-center overflow-hidden'>
        <img className='mx-auto object-cover rounded-3xl' src={'/logo.ico'} />
      </div>
      <div className="flex flex-col gap-1">
        <p className="font-bold text-xl">{capitalizeFirstLetter(data.title)}</p>
        <p className="font-medium text-sm">Total Token: {data.amount.toLocaleString()} ADSC</p>
        <p className="font-medium text-sm">Sold Token: {data.sold?.toLocaleString()} ADSC</p>
        <p className="font-medium text-sm">Sold Percentage: {Math.floor((data.sold || 0) / (data.amount || 0) * 100)}% out of 100%</p>
        <p className="font-medium text-sm">Collected USDT: {(data.sold || 0) * data.price || 0}</p>
        <p className="font-medium text-sm">Visited User: {data.visited_user?.length || 0}</p>
        <p className="font-medium text-sm">Participated User: {data.participated_user?.length || 0}</p>
      </div>
      <div className="flex gap-1">
        <p className="text-sm font-medium">Start: </p>
        <p className="my-auto text-xs font-medium text-[#ABABAB]">{getFormattedTime(data.start_at.toDateString())}</p>
      </div>
      <div className="flex gap-1">
        <p className="text-sm font-medium">End: </p>
        <p className="my-auto text-xs font-medium text-[#ABABAB]">{getFormattedTime(data.end_at.toDateString())}</p>
      </div>
    </div>
  )
}

export default DashboardCard;
