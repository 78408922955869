import { Button, Card } from "flowbite-react";
import { TokenPaymentType } from "../TokenPaymentType";
import { ConfirmPaymentModal, RejectPaymentModal } from "./TokenPaymentModal";
import { DateTime } from "luxon";
import { useQuery } from "@tanstack/react-query";
import {
  collection,
  getDocs,
  limit,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import FirebaseConfig from "../../../config/firebase";

export default function ({ item }: { item: TokenPaymentType }) {
  let created_at;
  if (typeof item.created_at == "string") {
    created_at = DateTime.fromISO(item.created_at).toJSDate();
  } else {
    created_at = item.created_at;
  }

  const { data, refetch, isFetching, isFetched } = useQuery(
    ["previous_token_payments", item.user_uid],
    async () => {
      let q = query(
        collection(FirebaseConfig.db, "token_payments"),
        where("user_uid", "==", item.user_uid),
        where("confirmed", "==", true),
        orderBy("created_at", "desc"),
        limit(3)
      );
      let querySnapshot = await getDocs(q);
      let data: Array<TokenPaymentType> = [];

      querySnapshot.forEach((doc) => {
        let tempData = doc.data();
        data.push({
          uid: doc.id,
          email: tempData["email"],
          confirmed: tempData["confirmed"],
          created_at: new Date(tempData["created_at"]["seconds"] * 1000),
          phase_uid: tempData["phase_uid"],
          is_cancel: tempData["is_cancel"],
          token: tempData["token"],
          token_bonus: tempData["token_bonus"],
          referral_token: tempData["referral_token"],
          reason: tempData["reason"],
          user_uid: tempData["user_uid"],
          tx_hash: tempData["tx_hash"],
          image: tempData["image"],
          paid_usdt_amount: tempData["paid_usdt_amount"],
          wallet_address: tempData["wallet_address"],
        });
      });
      return data;
    },
    { enabled: false, refetchOnMount: false }
  );

  return (
    <div className="w-full mr-2" key={item.uid}>
      <Card className="w-full">
        <div className="flex flex-col">
          <Button
            onClick={() => {
              refetch();
            }}
            disabled={isFetching}
          >
            {!isFetched ? "get previous Payment" : "refetch previous payment"}
          </Button>
          <div className="flex gap-x-8">
            <div className="flex-0 w-12">previous payment</div>
            <div className="flex-1 font-bold">
              <ul>
                {data && data.length == 0 && "empty"}
                {data &&
                  data.map((item, index) => {
                    return (
                      <li key={index}>
                        <div
                          className={`flex gap-x-2 ${
                            item.is_cancel ? "text-red-500" : " text-green-500"
                          }`}
                        >
                          <div>{item.is_cancel ? "reject" : "confirmed"}</div>
                          <div>{item.token}</div>
                          <div>
                            {typeof item.created_at == "string"
                              ? item.created_at
                              : item.created_at.toDateString()}
                          </div>
                          <div>{item.reason}</div>
                        </div>
                      </li>
                    );
                  })}
              </ul>
            </div>
          </div>
          <div className="flex gap-x-4">
            <div className="flex-0 w-12">Date</div>
            <div className="flex-1 font-bold">{created_at.toDateString()}</div>
          </div>
          <div className="flex gap-x-4 flex-wrap">
            <div className="flex-0 w-12">Email</div>
            <div className="flex-1 font-bold">{item.email}</div>
          </div>
          <div className="flex gap-x-4 flex-wrap">
            <div className="flex-0 w-12">Token</div>
            <div className="flex-1 font-bold">
              {item.token.toLocaleString("en-US")}
            </div>
          </div>
          <div className="flex gap-x-4 flex-wrap">
            <div className="flex-0 w-12">Bonus</div>
            <div className="flex-1 font-bold">
              {item.token_bonus.toLocaleString("en-US")}
            </div>
          </div>
          {item.tx_hash && (
            <div className="mt-3 border-t-2 border-gray-200">
              <div className="mt-3 font-bold">Confirmation Detail</div>
              <div>Transaction Hash: {item.tx_hash}</div>
              <div>Wallet Address: {item.wallet_address}</div>
              <div>Image: <img className="" src={item.image} alt="" /></div>
            </div>
          )}
          <div className="flex gap-x-4 mt-2">
            <div className="flex-1">
              <ConfirmPaymentModal selected_payment={item} />
            </div>
            <div className="flex-1">
              <RejectPaymentModal selected_payment={item} />
            </div>
          </div>
        </div>
      </Card>
    </div>
  );
}