import { Button, Dropdown, Label, TextInput } from "flowbite-react";
import TokenSalePhaseType from "./TokenSalePhaseType";
import { FormEvent } from "react";
import { DateTime } from "luxon";

export default function ({ phase, setPhase, handleSubmit }: { setPhase: React.Dispatch<React.SetStateAction<TokenSalePhaseType>>, phase: TokenSalePhaseType, handleSubmit: (e: FormEvent) => void }) {
  return (
    <form className="flex max-w-md flex-col gap-4" onSubmit={handleSubmit}>
      <div>
        <div className="mb-2 block">
          <Label
            htmlFor="title"
            value="title"
          />
        </div>
        <Dropdown id="title" label={phase.title || "phase --"} value={phase.title}>
          <Dropdown.Item onClick={() => setPhase((prev) => ({ ...prev, title: "phase 1" }))}>phase 1</Dropdown.Item>
          <Dropdown.Item onClick={() => setPhase((prev) => ({ ...prev, title: "phase 2" }))}>phase 2</Dropdown.Item>
          <Dropdown.Item onClick={() => setPhase((prev) => ({ ...prev, title: "phase 3" }))}>phase 3</Dropdown.Item>
          <Dropdown.Item onClick={() => setPhase((prev) => ({ ...prev, title: "phase 4" }))}>phase 4</Dropdown.Item>
          <Dropdown.Item onClick={() => setPhase((prev) => ({ ...prev, title: "phase 5" }))}>phase 5</Dropdown.Item>
          <Dropdown.Item onClick={() => setPhase((prev) => ({ ...prev, title: "phase 6" }))}>phase 6</Dropdown.Item>
        </Dropdown>
      </div>
      <div>
        <div className="mb-2 block">
          <Label
            htmlFor="type"
            value="type"
          />
        </div>
        <Dropdown id="type" label={phase.type || "Type --"} value={phase.type}>
          <Dropdown.Item onClick={() => setPhase((prev) => ({ ...prev, type: "web2" }))}>Web 2</Dropdown.Item>
          <Dropdown.Item onClick={() => setPhase((prev) => ({ ...prev, type: "web3" }))}>Web 3</Dropdown.Item>
        </Dropdown>
      </div>
      <div>
        <div className="mb-2 block">
          <Label
            htmlFor="start_at"
            value="start_at"
          />
        </div>
        <TextInput
          id="start_at"
          required
          type="datetime-local"
          value={`${DateTime.fromJSDate(phase.start_at).toFormat("yyyy-MM-dd")}T${DateTime.fromJSDate(phase.start_at).toFormat("T")}`}
          onChange={e => setPhase((prev) => ({ ...prev, start_at: new Date(e.target.value) }))}
        />
      </div>
      <div>
        <div className="mb-2 block">
          <Label
            htmlFor="end_at"
            value="end_at"
          />
        </div>
        <TextInput
          id="end_at"
          required
          type="datetime-local"
          value={`${DateTime.fromJSDate(phase.end_at).toFormat("yyyy-MM-dd")}T${DateTime.fromJSDate(phase.end_at).toFormat("T")}`}
          onChange={e => setPhase((prev) => ({ ...prev, end_at: new Date(e.target.value) }))}
        />
      </div>
      <div>
        <div className="mb-2 block">
          <Label
            htmlFor="price"
            value="price"
          />
        </div>
        <TextInput
          id="price"
          required
          step={"0.001"}
          type="number"
          value={phase.price}
          onChange={e => setPhase((prev) => ({ ...prev, price: parseFloat(e.target.value) }))}
        />
      </div>
      <div>
        <div className="mb-2 block">
          <Label
            htmlFor="amount"
            value="amount"
          />
        </div>
        <TextInput
          id="amount"
          required
          type="number"
          value={phase.amount}
          onChange={e => setPhase((prev) => ({ ...prev, amount: parseInt(e.target.value) }))}
        />
      </div>
      <div>
        <div className="mb-2 block">
          <Label
            htmlFor="maxPurchase"
            value="maximum purchase"
          />
        </div>
        <TextInput
          id="maxPurchase"
          required
          type="number"
          value={phase.max_purchase}
          onChange={e => setPhase((prev) => ({ ...prev, max_purchase: parseInt(e.target.value) }))}
        />
      </div>
      <Button disabled={!phase.title || !phase.type || !phase.amount || !phase.price 
      || !phase.max_purchase || !phase.start_at || !phase.end_at} type="submit">
        Submit
      </Button>
    </form>
  )
}