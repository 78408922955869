import { addDoc, collection, doc, serverTimestamp, updateDoc } from "firebase/firestore";
import FirebaseConfig from "../../../config/firebase";

export default async function ({ doc_uid, platform, title, link }: { doc_uid: string, platform: string, title: string, link: string }) {
  link = link.replace(/^https?:\/\//, '');
  await updateDoc(doc(FirebaseConfig.db, "social_media_news", doc_uid), {
    platform,
    title,
    link,
  });
}