import { Spinner } from 'flowbite-react'
import React, { useEffect } from 'react'
import { useAppSelector } from '../hook'
import scrollBlocker from '../utils/scrollBlocker';
import { useLottie } from 'lottie-react';
import lottieData from "../assets/loading.json";

function Loading() {
  const isFetch = useAppSelector((states) => states.loading.isFetching);
  const [blockScroll, allowScroll] = scrollBlocker();
  const defaultOptions = {
    loop: true,
    animationData: lottieData,
  };
  const { View: Lottie } = useLottie(defaultOptions);

  useEffect(() => {
    if (isFetch) blockScroll();
    return () => {
      allowScroll();
    }
  }, [isFetch]);

  return (
    <div className={`${!isFetch && 'hidden'} flex flex-col justify-center bg-black bg-opacity-60 h-screen w-screen fixed inset-0 z-[100]`}>
      <div className="bg-white mx-auto rounded-3xl w-80 lg:w-[420px] h-60 lg:h-80 flex flex-col gap-8 items-center justify-center z-[1000]">
        {Lottie}
        <p className="font-bold w-fit mx-auto text-sm lg:text-base">Doing some magic behind, please wait</p>
      </div>
    </div>
  )
}

export default Loading