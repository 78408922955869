import { MutationFunction, useMutation, UseMutationOptions, UseMutationResult } from "@tanstack/react-query"
import { useEffect } from "react";
import { changeLoadingState } from "../features/utils/loadingSlice";
import { useAppDispatch } from "../hook";

export default function useCustomMutation<TData = unknown, TError = unknown, TVariables = void, TContext = unknown> 
(fn: MutationFunction<TData, TVariables>, options?: Omit<UseMutationOptions<TData, TError, TVariables, TContext>, 'mutationKey'>)
: UseMutationResult<TData, TError, TVariables, TContext> {
  const dispatch = useAppDispatch();
  const resultMutate = useMutation(fn, { ...options,
    onSettled: (result, err, variables) => {
      if (options?.onSettled) options.onSettled(result, err, variables, undefined)
      dispatch(changeLoadingState({ isFetching: false }))
    }
  });
  
  useEffect(() => {
    if (resultMutate.isLoading) dispatch(changeLoadingState({ isFetching: resultMutate.isLoading })); 
  }, [resultMutate.isLoading]);

  return resultMutate;
}