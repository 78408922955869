import { Sidebar } from "flowbite-react";
import {
  HiChartPie,
  HiInbox,
  HiMenu,
  HiViewBoards,
  HiCurrencyDollar,
  HiOutlineNewspaper,
  HiReceiptTax,
  HiLockClosed
} from "react-icons/hi";
import { GoHistory } from 'react-icons/go'
import { Outlet, useNavigate } from "react-router-dom";
import { MdExitToApp } from "react-icons/md";
import { SiProgress } from "react-icons/si";
import { GrUserAdmin, GrUserPolice } from "react-icons/gr";
import { useEffect, useState } from "react";
import FirebaseConfiguration from "./config/firebase";
import useCustomMutation from "./utils/useCustomMutation";
import { useQuery } from "@tanstack/react-query";
import { httpsCallable } from "firebase/functions";
import { getToken, onMessage } from "firebase/messaging";
import getAdminProfile from "./getAdminProfile";
import AdminNotificationToast from "./AdminNotificationToast";
import Loading from "./components/Loading";
import logoutService from "./services/logoutService";
import { MdPayments } from "react-icons/md";
import { RiRefund2Line } from "react-icons/ri";
import { FaXbox } from "react-icons/fa";
import { BsClipboardDataFill } from "react-icons/bs";
import { useAppDispatch } from "./hook";
import { changeUserPermissionsState } from "./features/utils/userPermissionsSlice";

const useCheckIfAdmin: () => {
  is_fetched: boolean;
  is_admin?: boolean;
} = () => {
  const dispatch = useAppDispatch();
  const [data, setData] = useState<{ is_fetched: boolean; is_admin?: boolean }>(
    { is_fetched: false }
  );
  useEffect(() => {
    FirebaseConfiguration.auth.onAuthStateChanged(async (user) => {
      if (!user) return setData({ ...data, is_fetched: true, is_admin: false });
      const id_token_result = await user?.getIdTokenResult();
      if (id_token_result.claims.role !== "admin" && id_token_result.claims.role !== "low_level_admin") {
        return setData({ ...data, is_fetched: true, is_admin: false });
      }
      if (id_token_result.claims.role === "low_level_admin") dispatch(changeUserPermissionsState({ permissions: id_token_result.claims.permissions }));
      else if (id_token_result.claims.role === "admin") dispatch(changeUserPermissionsState({ permissions: ["all"] }));
      return setData({ ...data, is_fetched: true, is_admin: true });
    });
  }, []);
  return data;
};

export default function () {
  const messaging = FirebaseConfiguration.messaging;
  const navigate = useNavigate();
  const { is_fetched, is_admin } = useCheckIfAdmin();
  const logout_mutation = useCustomMutation(logoutService, {
    onSuccess: () => {
      navigate("/login");
    },
  });
  useEffect(() => {
    if (is_fetched && is_admin == false) {
      handleLogout();
    }
  }, [is_fetched]);
  const handleLogout = () => {
    logout_mutation.mutate();
  };
  const [collapsed, setCollapsed] = useState(false);

  useEffect(() => {
    if (document.body.clientWidth && document.body.clientWidth < 640)
      setCollapsed(true);
  }, []);
  const [fetchUpdateToken, setFetchUpdateToken] = useState("");
  const updateFcmToken = httpsCallable(
    FirebaseConfiguration.functions,
    "subscribeToAdminTopics"
  );
  const handleUpdateToken = async (currentFcmToken?: string) => {
    if (!messaging) return;
    if (Notification.permission !== "granted") return;
    const token = await getToken(messaging, {
      vapidKey:
        "BNvblrk3v56uYeffTG1MLwHTpseCA2fHQbQw3nc8Cx9R2fNNzSHWokbNV44FqQ0LeCczWp-70XcrXfuUmwNFbH8",
    });
    if (fetchUpdateToken != "") return;
    setFetchUpdateToken(token);
    if (token == currentFcmToken) return;
    updateFcmToken({ fcmToken: token, currentFcmToken });
  };
  const [notifications, setNotifications] = useState<
    { title?: string; message?: string }[]
  >([]);

  if (messaging && Notification.permission === "granted") {
    onMessage(messaging, (payload: any) => {
      setNotifications((prev) => {
        return [
          ...prev,
          {
            title: payload.notification.title,
            message: payload.notification.body,
          },
        ];
      });
    });
  }

  const uid = FirebaseConfiguration.auth.currentUser?.uid || "";
  const profile = useQuery(["adminProfile", uid], () => getAdminProfile(uid), {
    enabled: uid != "",
    staleTime: 1000 * 60 * 5,
  });
  useEffect(() => {
    if (profile.data && messaging) handleUpdateToken(profile.data.fcm_token);
  }, [profile.data]);

  if (!is_fetched) return <Loading />;

  return (
    <div>
      <AdminNotificationToast
        notifications={notifications}
        setNotifications={setNotifications}
      />
      <div className="flex p-2">
        <div className="my-auto px-2 cursor-pointer">
          <HiMenu size={36} onClick={() => setCollapsed(!collapsed)} />
        </div>
        <div className=" my-auto font-bold text-4xl">Audisc Admin</div>
      </div>
      <div className="flex">
        <div className="w-fit">
          <Sidebar aria-label="Default sidebar example" collapsed={collapsed}>
            <Sidebar.Items>
              <Sidebar.ItemGroup>
                <Sidebar.Item
                  href="#"
                  onClick={() => navigate("/dashboard")}
                  icon={HiViewBoards}
                >
                  dashboard
                </Sidebar.Item>
                <Sidebar.Item
                  href="#"
                  onClick={() => navigate("/payment")}
                  icon={HiInbox}
                >
                  Payment
                </Sidebar.Item>
                <Sidebar.Item
                  href="#"
                  onClick={() => navigate("/token-payment")}
                  icon={HiCurrencyDollar}
                >
                  Token Payment
                </Sidebar.Item>
                <Sidebar.Item
                  href="#"
                  onClick={() => navigate("/deposit")}
                  icon={MdPayments}
                >
                  Deposit
                </Sidebar.Item>
                <Sidebar.Item
                  href="#"
                  onClick={() => navigate("/revert-deposit-payment")}
                  icon={RiRefund2Line}
                >
                  Revert Deposit Payment
                </Sidebar.Item>
                <Sidebar.Item
                  onClick={() => navigate("/product")}
                  href="#"
                  icon={FaXbox}
                >
                  Product
                </Sidebar.Item>
                <Sidebar.Item
                  onClick={() => navigate("/auction-data")}
                  href="#"
                  icon={BsClipboardDataFill}
                >
                  Auction Data
                </Sidebar.Item>
                <Sidebar.Item
                  onClick={() => navigate("/claim")}
                  href="#"
                  icon={HiChartPie}
                >
                  Claim
                </Sidebar.Item>
                <Sidebar.Item
                  onClick={() => navigate("/token-sale-phase")}
                  href="#"
                  icon={SiProgress}
                >
                  Token Sale Phase
                </Sidebar.Item>
                <Sidebar.Item
                  onClick={() => navigate("/history-token-payment")}
                  href="#"
                  icon={GoHistory}
                >
                  History Token Payment
                </Sidebar.Item>
                <Sidebar.Item
                  onClick={() => navigate("/social-media-news")}
                  href="#"
                  icon={HiOutlineNewspaper}
                >
                  Social Media News
                </Sidebar.Item>
                <Sidebar.Item
                  onClick={() => navigate("/voucher")}
                  href="#"
                  icon={HiReceiptTax}
                >
                  Voucher
                </Sidebar.Item>
                <Sidebar.Item
                  onClick={() => navigate("/admin-management")}
                  href="#"
                  icon={GrUserAdmin}
                >
                  Admin Management
                </Sidebar.Item>
                <Sidebar.Item
                  onClick={() => navigate("/block-user")}
                  href="#"
                  icon={GrUserPolice}
                >
                  Block User
                </Sidebar.Item>
                <Sidebar.Item
                  onClick={() => navigate("/change-password")}
                  href="#"
                  icon={HiLockClosed}
                >
                  Change Password
                </Sidebar.Item>
                <Sidebar.Item onClick={handleLogout} icon={MdExitToApp}>
                  Sign Out
                </Sidebar.Item>
              </Sidebar.ItemGroup>
            </Sidebar.Items>
          </Sidebar>
        </div>
        <Outlet />
        <div />
      </div>
    </div>
  );
}
