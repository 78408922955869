import {
  collection,
  getDocs,
  orderBy,
  query,
  where,
  limit,
  startAfter,
  Timestamp,
} from "firebase/firestore";
import { VoucherType } from "./VoucherType";
import FirebaseConfig from "../../config/firebase";
import { DateTime } from "luxon";
export default async () => {
  let q = query(
    collection(FirebaseConfig.db, "vouchers"),
    orderBy("valid_until", "desc")
  );
  const querySnapshot = await getDocs(q);
  const data: Array<VoucherType> = [];
  querySnapshot.forEach((doc) => {
    let tempData = doc.data();
    const valid_until = tempData.valid_until as Timestamp;
    const start_from = tempData.start_from as Timestamp;
    data.push({
      uid: doc.id,
      code: tempData.code,
      name: tempData.name,
      limit_per_user: tempData.limit_per_user,
      discount_percentage: tempData.discount_percentage,
      start_from: start_from.toDate(),
      valid_until: valid_until.toDate(),
      voucher_amount: tempData.voucher_amount,
      max_discount_per_voucher: tempData.max_discount_per_voucher,
      emails: tempData.emails,
    });
  });
  return data;
};
