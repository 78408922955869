import { DocumentData, DocumentReference, DocumentSnapshot, Transaction, doc, increment, runTransaction } from "firebase/firestore";
import FirebaseConfig from "../../../config/firebase";
import { DepositType } from "../../deposit/DepositType";

export default async function(data: DepositType) {
  await runTransaction(FirebaseConfig.db, async (transaction) => {
    const depositPaymentRef = doc(FirebaseConfig.db, "transactions", data.uid);
    const tokenPaymentSnap = await transaction.get(depositPaymentRef);

    const profileRef = doc(
      FirebaseConfig.db,
      "profile",
      data.user_uid
    );

    if (!tokenPaymentSnap.exists()) throw new Error("payment not exists");

    return await updateConfirmPayment(
      transaction,
      depositPaymentRef,
      data,
      profileRef,
    );
  });
}

async function updateConfirmPayment(
  transaction: Transaction,
  depositPaymentRef: DocumentReference<DocumentData>,
  payment: DepositType,
  profileRef: DocumentReference<DocumentData>,
) {

  transaction.update(depositPaymentRef, {
    confirmed: false,
  });

  transaction.update(profileRef, {
    usdt_amount: increment(-payment.amount),
  });
}