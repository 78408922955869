import { doc, getDoc } from "firebase/firestore";
import FirebaseConfiguration from "./config/firebase";
import { ProfileType } from "./types/ProfileType";

export interface AdminProfile extends ProfileType {
  fcm_token?: string;
}
export default async function (uid: string) {
  const ref = doc(FirebaseConfiguration.db, "profile/" + uid);
  const snap = await getDoc(ref);
  if (!snap.data()) return { name: "", phone_number: "" } as AdminProfile;
  return snap.data() as AdminProfile;
}
