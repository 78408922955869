import React, { useState } from 'react';
import './assets/App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import AdminLogin from './AdminLogin';
import AdminRoot from './AdminRoot';
import AdminDashboard from './AdminDashboard';
import AdminPayment from './features/payments/AdminPayment';
import AdminTokenPayment from './features/token/AdminTokenPayment';
import AdminProduct from './features/products/AdminProduct';
import AdminClaim from "./features/claims";
import { QueryClient } from '@tanstack/react-query';
import { createIDBPersister } from "./utils/createIDBPersister";
import { PersistQueryClientProvider } from '@tanstack/react-query-persist-client';
import { Provider } from 'react-redux';
import { store } from './store';
import PreventScrollContext from './utils/PreventScrollContext';
import AdminInit from './AdminInit';
import TokenSalePhase from './features/tokenSalePhase/TokenSalePhase';
import TokenSalePhaseCreate from './features/tokenSalePhase/TokenSalePhaseCreate';
import TokenSalePhaseEdit from './features/tokenSalePhase/TokenSalePhaseEdit';
import SocialMediaNews from './features/socialMediaNews/SocialMediaNews';
import Loading from './components/Loading';
import AdminDeposit from './features/deposit/AdminDeposit';
import RevertDepositPayment from './features/revertPayment/RevertDepositPayment';
import VoucherList from './features/voucher/VoucherList';
import VoucherCreate from './features/voucher/VoucherCreate';
import AuctionData from './features/auctionData';
import AdminManagement from './features/adminManagement';
import ChangePassword from './features/changePassword';
import HistoryTokenPayment from './features/tokenSalePhase/HistoryTokenPayment';
import BlockUser from './features/blockUser';

function App() {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        cacheTime: 1000 * 60 * 60,
      },
    },
  });
  const persister = createIDBPersister();
  const [preventScroll, setPreventScroll] = useState(false);

  return (
    <PersistQueryClientProvider client={queryClient} persistOptions={{ persister }}>
      <Provider store={store}>
        <PreventScrollContext.Provider value={{ preventScroll, setPreventScroll }}>
          <Loading />
          <BrowserRouter>
            <Routes>
              <Route path="/login" element={<AdminLogin />} />
              <Route path="/init" element={<AdminInit />} />
              <Route path="/" element={<AdminRoot />}>
                <Route index element={<AdminDashboard />} />
                <Route path="dashboard" element={<AdminDashboard />} />
                <Route path="claim" element={<AdminClaim />} />
                <Route path="payment" element={<AdminPayment />} />
                <Route path="token-payment" element={<AdminTokenPayment />} />
                <Route path="deposit" element={<AdminDeposit />} />
                <Route path="auction-data" element={<AuctionData />} />
                <Route path="product" element={<AdminProduct />} />
                <Route path='token-sale-phase' element={<TokenSalePhase />} />
                <Route path='token-sale-phase/create' element={<TokenSalePhaseCreate />} />
                <Route path='token-sale-phase/edit' element={<TokenSalePhaseEdit />} />
                <Route path='history-token-payment' element={<HistoryTokenPayment />} />
                <Route path="social-media-news" element={<SocialMediaNews />} />
                <Route path="revert-deposit-payment" element={<RevertDepositPayment />} />
                <Route path="voucher" element={<VoucherList />} />
                <Route path="voucher/create" element={<VoucherCreate />} />
                <Route path="admin-management" element={<AdminManagement />} />
                <Route path="block-user" element={<BlockUser />} />
                <Route path="change-password" element={<ChangePassword />} />
              </Route>
            </Routes>
          </BrowserRouter>
        </PreventScrollContext.Provider>
      </Provider>
    </PersistQueryClientProvider>
  );
}

export default App;
