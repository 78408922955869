import { Button } from '@mui/material'
import React, { useState } from 'react'
import { RiAccountPinCircleFill } from 'react-icons/ri'
import PopUpDeleteAdmin from './PopUpDeleteAdmin'

function AdminItem({ email }: { email: string }) {
  const [showPopUpDelete, setShowPopUpDelete] = useState(false);

  return (
    <div className="relative rounded-2xl p-4 shadow-md border w-56 h-fit">
      {showPopUpDelete && <PopUpDeleteAdmin email={email} setShowPopUpDelete={setShowPopUpDelete} />}
      <RiAccountPinCircleFill className="text-5xl mx-auto" />
      <p className="font-medium mt-2">Email: </p>
      <p className="break-words ">{email}</p>
      <Button onClick={() => setShowPopUpDelete(true)} className="mt-5" variant='contained' color='error'>Delete</Button>
    </div>
  )
}

export default AdminItem;
