import {
} from "firebase/firestore";
import { httpsCallable } from "firebase/functions";
import FirebaseConfig from "../../../config/firebase";

export default async function ({ email, permissions }: { email: string, permissions: Array<string> }) {
  const functions = FirebaseConfig.functions;
  const addAdmin = httpsCallable(functions, "addAdmin");
  const res = await addAdmin({
    email,
    permissions,
  });

  return res;
}
