import { useMutation } from "@tanstack/react-query"
import createVoucher from "./createVoucher"
import { VoucherType } from "./VoucherType"
import { useState } from "react"
import { Button, FileInput, Label, TextInput, Textarea, Toast } from "flowbite-react"
import { useNavigate } from "react-router-dom"
import { HiFire } from "react-icons/hi"
import Papa from 'papaparse';
import { useAppSelector } from "../../hook"
import getVoucherByCode from "./getVoucherByCode"

export default function () {
  const navigate = useNavigate();
  const [error, setError] = useState("");
  const [validVoucherCodeError, setValidVoucherCodeError] = useState("");
  const mutation = useMutation({
    mutationFn: (newVoucher: VoucherType) => {
      return createVoucher(newVoucher)
    },
    onSuccess: () => {
      navigate("/voucher");
    },
    onError: (err: any) => {
      setError(err.message)
    }
  })
  const [newVoucher, setNewVoucher] = useState<VoucherType>({
    name: "",
    code: "",
    limit_per_user: 1,
    discount_percentage: 1,
    start_from: new Date(),
    valid_until: new Date(),
    voucher_amount: 1,
    max_discount_per_voucher: 1,
    emails: []
  });

  const handleCreate = async (e: React.SyntheticEvent) => {
    e.preventDefault()
    if (await getVoucherByCode(newVoucher.code)) {
      return setValidVoucherCodeError("voucher already exists")
    }
    mutation.mutate(newVoucher);
  }

  const handleUploadEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files || !(e.target.files?.length > 0)) return;
    Papa.parse(e.target.files[0], {
      header: true,
      error: (err: any) => { console.log(err) },
      complete: (result: any) => {
        const emails = result.data.map((v: any) => {
          if (v.email !== '') return v.email
        })
        setNewVoucher((prev) => ({ ...prev, emails }))
      }
    })
  }
  const permissions = useAppSelector((states) => states.userPermissions.permissions);

  if (!permissions.includes("voucher") && !permissions.includes("all")) return (
    <div className="flex flex-col justify-center w-full">
      <p className="my-auto text-2xl text-center animate-bounce">You don't have permission to access this feature</p>
    </div>
  )
  const formatDatetime = (datetime: number) => {
    const selectedDateTime = new Date(datetime) || new Date();
    const selectedHours = selectedDateTime.getHours();
    const offsetHours = (new Date(datetime) || new Date()).getTimezoneOffset() / 60
    selectedDateTime.setHours(selectedHours + offsetHours)
    return selectedDateTime;
  }

  return <form className="flex flex-col max-w-md gap-4" onSubmit={handleCreate} action="#">
    <div>
      <div className="block mb-2">
        <Label
          htmlFor="code"
          value="code"
        />
      </div>
      <TextInput
        onChange={(e) => { setNewVoucher({ ...newVoucher, code: e.target.value }) }}
        id="code"
        placeholder="FREE_TRIAL"
        required
        type="text"
        value={newVoucher.code}
      />
      {validVoucherCodeError && <div className="text-red-500 ">{validVoucherCodeError}</div>}
    </div>
    <div>
      <div className="block mb-2">
        <Label
          htmlFor="name"
          value="name"
        />
      </div>
      <TextInput
        onChange={(e) => { setNewVoucher({ ...newVoucher, name: e.target.value }) }}
        id="name"
        placeholder="free trial"
        required
        type="text"
        value={newVoucher.name}
      />
    </div>
    <div>
      <div className="block mb-2">
        <Label
          htmlFor="limit_per_user"
          value="limit_per_user"
        />
      </div>
      <TextInput
        onChange={(e) => { setNewVoucher({ ...newVoucher, limit_per_user: e.target.valueAsNumber }) }}
        id="limit_per_user"
        placeholder="free trial"
        required
        type="number"
        value={newVoucher.limit_per_user}
      />
    </div>
    <div>
      <div className="block mb-2">
        <Label
          htmlFor="discount_percentage"
          value="discount_percentage"
        />
      </div>
      <TextInput
        onChange={(e) => { setNewVoucher({ ...newVoucher, discount_percentage: e.target.valueAsNumber }) }}
        id="discount_percentage"
        placeholder="free trial"
        required
        type="number"
        value={newVoucher.discount_percentage}
      />
    </div>
    <div>
      <div className="block mb-2">
        <Label
          htmlFor="voucher_amount"
          value="voucher_amount"
        />
      </div>
      <TextInput
        onChange={(e) => { setNewVoucher({ ...newVoucher, voucher_amount: e.target.valueAsNumber }) }}
        id="voucher_amount"
        placeholder="free trial"
        required
        type="number"
        value={newVoucher.voucher_amount}
      />
    </div>
    <div>
      <div className="block mb-2">
        <Label
          htmlFor="max_discount_per_voucher"
          value="max_discount_per_voucher"
        />
      </div>
      <TextInput
        onChange={(e) => { setNewVoucher({ ...newVoucher, max_discount_per_voucher: e.target.valueAsNumber }) }}
        id="max_discount_per_voucher"
        placeholder="free trial"
        required
        type="number"
        value={newVoucher.max_discount_per_voucher}
      />
    </div>
    <div>
      <div className="block mb-2">
        <Label
          htmlFor="start_from"
          value="start_from"
        />
      </div>
      <TextInput
        onChange={(e) => {
          setNewVoucher({ ...newVoucher, start_from: formatDatetime(e.target.valueAsNumber) });
        }}
        id="start_from"
        placeholder="start from"
        required
        type="datetime-local"
      />
    </div>
    <div>
      <div className="block mb-2">
        <Label
          htmlFor="valid_until"
          value="valid_until"
        />
      </div>
      <TextInput
        onChange={(e) => {
          setNewVoucher({ ...newVoucher, valid_until: formatDatetime(e.target.valueAsNumber) });
        }}
        id="valid_until"
        placeholder="valid until"
        required
        type="datetime-local"
      />
    </div>
    <div>
      <div className="block mb-2">
        <Label
          htmlFor="emails"
          value="emails"
        />
      </div>
      <FileInput
        id="emailsInput"
        onChange={handleUploadEmail}
      />
    </div>
    {error !== "" && (
      <Toast>
        <div className="inline-flex items-center justify-center w-8 h-8 text-red-500 bg-red-100 rounded-lg shrink-0 dark:bg-red-800 dark:text-red-200">
          <HiFire className="w-5 h-5" />
        </div>
        <div className="ml-3 text-sm font-normal">
          {error}
        </div>
        <Toast.Toggle />
      </Toast>
    )}
    <div><Button type="submit">Create</Button></div>
  </form>
}