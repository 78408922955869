import { Button, Spinner } from "flowbite-react";
import { useState } from "react";
import adminFetchLoginService from "./adminFetchLoginService";
import { useNavigate } from "react-router";
import useCustomMutation from "./utils/useCustomMutation";

type inputError = { email?: string, password?: string, other?: string }
export default function Login() {
  const [input, setInput] = useState({ email: "", password: "" })
  const [inputError, setInputError] = useState<inputError>({})
  const handleChangeInput = (e: React.FormEvent<HTMLInputElement>) => {
    setInputError({})
    let name = e.currentTarget.name
    let value = e.currentTarget.value
    setInput({ ...input, [name]: value })
  }
  const validateEmail = (email: string) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };
  const validateInput = () => {
    let error: inputError = {}
    if (input.email == "") {
      error.email = "Email is required"
    } else {
      if (!validateEmail(input.email)) error.email = "email is not valid"
    }
    if (input.password == "") {
      error.password = "Password is required"
    }
    if (error.email || error.password) {
      setInputError({ ...inputError, ...error })
      return false;
    }
    return true
  }
  const handleLogin = (e: any) => {
    e.preventDefault()
    if (validateInput())
      login_mutation.mutate({ email: input.email, password: input.password })
  }
  const navigate = useNavigate();
  const login_mutation = useCustomMutation(adminFetchLoginService, {
    onSuccess: async (data) => { 
      const idTokenResult = await data.user.getIdTokenResult();
      if (idTokenResult.claims.role !== "admin" && idTokenResult.claims.role !== "low_level_admin") setInputError({ other: "You're not an admin" })
      else navigate("/");
    },
    onError: (e: any) => { setInputError({ other: e.message }) }
  })
  return <div><div className="p-20">
    <div className=" text-5xl text-center mb-5">Login</div>
    <form>
      <div className="relative z-0 w-full mb-6 group">
        <input type="email" name="email" id="email" className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " required value={input.email} onChange={handleChangeInput} />
        <label htmlFor="email" className={`peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6 ${inputError.email && ' text-red-500'}`}>Email address</label>
        <div className="text-red-500 text-sm">{inputError.email}</div>
      </div>
      <div className="relative z-0 w-full mb-6 group">
        <input type="password" name="password" id="password" className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " required value={input.password} onChange={handleChangeInput} />
        <label htmlFor="password" className={`peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6 ${inputError.email && ' text-red-500'}`}>Password</label>
        <div className="text-red-500 text-sm">{inputError.password}</div>
      </div>
      <div className=" text-red-500 text-sm mb-6">{inputError.other}</div>
      <Button className="bg-blue-700" type="submit" onClick={handleLogin} disabled={login_mutation.isLoading}>
        Submit <div className="ml-2">{login_mutation.isLoading && <Spinner />}</div>
      </Button>
    </form>
  </div>
  </div>
}