import {
  collection,
  doc,
  getDocs,
  orderBy,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import ClaimType from "./ClaimType";
import FirebaseConfig from "../../config/firebase";
export const getUncompleteClaim = async () => {
  const q = query(
    collection(FirebaseConfig.db, "claims"),
    where("prize_claimed", "==", false),
    orderBy("created_at", "desc")
  );
  const querySnapshot = await getDocs(q);
  const data: Array<ClaimType> = [];
  querySnapshot.forEach((doc) => {
    let docData = doc.data();
    data.push({
      uid: doc.id,
      created_at: new Date(docData.created_at.seconds * 1000),
      phone_number: docData.phone_number,
      prize_claimed: docData.prize_claimed,
      product_uid: docData.product_uid,
      user_uid: docData.user_uid,
    });
  });
  return data;
};

export const claimPrize = async (uid: string) => {
  const claimRef = doc(FirebaseConfig.db, "claims", uid);
  await updateDoc(claimRef, { prize_claimed: true });
};
