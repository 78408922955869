import { useQuery } from '@tanstack/react-query';
import getAllAdmin from '../services/getAllAdmin';
import AdminItem from './AdminItem';

function AdminList() {
  const { data } = useQuery(["admin_list"], getAllAdmin);

  return (
    <div className="mt-5 flex">
      {data?.map((e, index) => <AdminItem key={index} email={e.email} /> )}
    </div>
  )
}

export default AdminList;
