import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { v4 as uuidv4 } from "uuid";
import {
  collection,
  getDocs,
  orderBy,
  query,
  where,
  limit,
  startAfter,
} from "firebase/firestore";
import { DepositType } from "./DepositType";
import FirebaseConfig from "../../config/firebase";

export const getPayment = async (created_at?: Date) => {
  let q = query(
    collection(FirebaseConfig.db, "transactions"),
    where("confirmed", "==", false),
    where("type", "==", "top_up"),
    orderBy("created_at", "asc"),
    limit(5)
  );
  if (created_at) {
    q = query(
      collection(FirebaseConfig.db, "transactions"),
      where("confirmed", "==", false),
      where("type", "==", "top_up"),
      orderBy("created_at", "asc"),
      startAfter(created_at),
      limit(5)
    );
  }
  const querySnapshot = await getDocs(q);
  const data: Array<DepositType> = [];
  for (const doc of querySnapshot.docs) {
    let tempData = doc.data();
    data.push({
      uid: doc.id,
      amount: tempData["usdt_amount"],
      confirmed: tempData["confirmed"],
      created_at: new Date(tempData["created_at"]["seconds"] * 1000),
      is_cancel: tempData["is_cancel"],
      transaction_hash: tempData["transaction_hash"],
      user_uid: tempData["user_uid"],
      email: tempData["email"],
      image: tempData["image"],
    });
  }
  return data;
};
