import { Button, Card, Modal } from "flowbite-react";
import { useState } from "react";
import { useQueryClient } from "@tanstack/react-query";
import { deleteProduct } from "./apiProduct";
import useCustomMutation from "../../utils/useCustomMutation";
import { Product } from "../../types/ProductType";
import EditProductModal from "./EditProductModal";


export default function ({ data }: { data: Product }) {

  const [show, setShow] = useState(false)
  const [showCreateModal, setShowCreateModal] = useState(false);
  const showRemoveConfirmation = () => {
    setShow(true)
  }
  const [error, setError] = useState("")
  const queryClient = useQueryClient();
  const mutation = useCustomMutation(deleteProduct, {
    onSuccess: () => {
      queryClient.invalidateQueries(['products'])
      setShow(false)
    },
    onError: (e: Error) => {
      setError(e.message)
    }
  })
  const confirmRemove = () => {
    mutation.mutate(data.uid)
  }

  return <Card className="mt-2">

    <EditProductModal show={showCreateModal} onClose={() => setShowCreateModal(false)} data={data} />
    <Modal show={show}>
      <Modal.Body>
        Are you sure to delete this?
        {error}
      </Modal.Body>
      <Modal.Footer className="flex">
        <Button className="flex-1" color="failure" onClick={confirmRemove}>Remove</Button>
        <Button className="flex-1" color="gray" onClick={() => setShow(false)}>Cancel</Button>
      </Modal.Footer>
    </Modal>
    <div className="flex flex-col">
      <div className="flex">
        <div className="flex-0">
          <img className="object-contain w-20 h-20 rounded" src={data.image ? data.image[0] : ""} />
        </div>
        <div className="flex-1 ml-2">
          <div className="font-bold">{data.name}</div>
          <div className="p-1 text-sm font-light border-2 border-solid rounded ">
            <div className="border-b-2 ">{data.start_time.toString()}</div>
            <div>{data.end_time.toString()}</div>
          </div>
        </div>
      </div>
      <div className="flex justify-between">
        <div>{data.lowest_price} - {data.highest_price} USDT</div>
        <div>{data.usdt_amount}</div>
      </div>
      <div className="flex mt-2 w-full gap-4">
        <Button className="flex-1" onClick={() => { setShowCreateModal(true) }}>Edit</Button>
        <Button color={"failure"} className="flex-1" onClick={showRemoveConfirmation}>Remove</Button>
        </div>
    </div>
  </Card>
}