import {
  DocumentData,
  DocumentReference,
  DocumentSnapshot,
  Transaction,
  addDoc,
  collection,
  getDoc,
  runTransaction,
  setDoc,
  query,
  where,
  getDocs,
} from "firebase/firestore";
import { doc, updateDoc } from "firebase/firestore";
import { PaymentType } from "./PaymentType";
import FirebaseConfig from "../../config/firebase";

export default async function (
  payment: PaymentType,
  action: "confirm" | "reject",
  reason: string = ""
) {
  await runTransaction(FirebaseConfig.db, async (transaction) => {
    const paymentRef = doc(FirebaseConfig.db, "payments", payment.uid);
    const paymentSnap = await transaction.get(paymentRef);
    const profileRef = doc(
      FirebaseConfig.db,
      "profile",
      payment.user_uid
    );
    const queryProfile = query(collection(FirebaseConfig.db, 'profile'), where('referral_users', 'array-contains', payment.user_uid));
    const docsRef = await getDocs(queryProfile);
    const referralRef = docsRef.docs.length > 0 ? docsRef.docs[0].ref : null;
    const referralSnap = referralRef ? await transaction.get(referralRef) : null;
    const profileSnap = await transaction.get(profileRef);

    if (!paymentSnap.exists()) {
      throw new Error("payment not exists");
    }
    if (action == "confirm")
      return await updateConfirmPayment(
        transaction,
        paymentRef,
        payment,
        profileRef,
        profileSnap,
        referralRef,
        referralSnap,
      );
    await updateRejectPayment(transaction, paymentRef, reason);
  });
}
async function updateRejectPayment(
  transaction: Transaction,
  paymentRef: DocumentReference<DocumentData>,
  reason: string
) {
  await transaction.update(paymentRef, {
    confirmed: true,
    is_cancel: true,
    reason: reason,
  });
}

async function updateConfirmPayment(
  transaction: Transaction,
  paymentRef: DocumentReference<DocumentData>,
  payment: PaymentType,
  profileRef: DocumentReference<DocumentData>,
  profileSnap: DocumentSnapshot<DocumentData>,
  referralRef: DocumentReference<DocumentData> | null,
  referralSnap: DocumentSnapshot<DocumentData> | null
) {
  transaction.update(paymentRef, {
    confirmed: true,
  });

  if (!profileSnap.exists()) {
    transaction.set(profileRef, {
      ticket_amount: payment.ticket + payment.ticket_bonus,
    });
  }
  else {
    let ticketData = profileSnap.data();
    let ticket_amount =
      ticketData && ticketData.ticket_amount && ticketData.ticket_amount > 0
        ? ticketData.ticket_amount
        : 0;
    transaction.update(profileRef, {
      ticket_amount: ticket_amount + payment.ticket + payment.ticket_bonus,
    });
  }
  if (referralRef && referralSnap) {
    let ticketData = referralSnap.data();
    let ticket_amount = ticketData && ticketData.ticket_amount && ticketData.ticket_amount > 0? ticketData.ticket_amount: 0;

    transaction.update(referralRef, {
      ticket_amount: ticket_amount + 1
    });
  }
}
