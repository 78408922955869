import { Toast } from "flowbite-react";
import { HiFire } from "react-icons/hi";

export default function ({ notifications, setNotifications }: {
  notifications: { title?: string, message?: string }[], setNotifications: React.Dispatch<React.SetStateAction<{
    title?: string | undefined;
    message?: string | undefined;
  }[]>>
}) {
  return <div className=" absolute top-4 right-4">
    {notifications.map((value, index) => {
      return <Toast key={index}>
        <div className="inline-flex h-8 w-8 shrink-0 items-center justify-center rounded-lg bg-blue-100 text-blue-500 dark:bg-blue-800 dark:text-blue-200">
          <HiFire className="h-5 w-5" />
        </div>
        <div className="ml-3 text-sm font-normal">
          <div className="font-semibold text-lg">{value.title}</div>
          <div>{value.message}</div>
        </div>
        <Toast.Toggle />
      </Toast>
    })}
  </div>
}