import {
  collection,
  getDocs,
  orderBy,
  query,
  where,
  limit,
  startAfter,
} from "firebase/firestore";
import { TokenPaymentType } from "../TokenPaymentType";
import FirebaseConfig from "../../../config/firebase";
import { getDownloadURL, ref } from "firebase/storage";

export default async (created_at?: Date) => {
  let q = query(
    collection(FirebaseConfig.db, "token_payments"),
    where("confirmed", "==", false),
    orderBy("created_at", "asc"),
    limit(5)
  );
  if (created_at) {
    q = query(
      collection(FirebaseConfig.db, "token_payments"),
      where("confirmed", "==", false),
      orderBy("created_at", "asc"),
      startAfter(created_at),
      limit(5)
    );
  }
  const querySnapshot = await getDocs(q);
  const data: Array<TokenPaymentType> = [];
  for (const doc of querySnapshot.docs) {
    let tempData = doc.data();
    let imageUrl = "";
    if (tempData.image) {
      const imagesRef = ref(FirebaseConfig.storage, tempData.image);
      try {
        imageUrl = await getDownloadURL(imagesRef);
      } catch (e: any) {
        console.log(e.message);
      }
    }
    
    data.push({
      uid: doc.id,
      email: tempData["email"],
      confirmed: tempData["confirmed"],
      created_at: new Date(tempData["created_at"]["seconds"] * 1000),
      phase_uid: tempData["phase_uid"],
      is_cancel: tempData["is_cancel"],
      token: tempData["token"],
      token_bonus: tempData["token_bonus"],
      referral_token: tempData["referral_token"],
      user_uid: tempData["user_uid"],
      tx_hash: tempData["tx_hash"],
      image: imageUrl,
      paid_usdt_amount: tempData["paid_usdt_amount"],
      wallet_address: tempData["wallet_address"],
    });
  }
  return data;
};