import { useQuery } from '@tanstack/react-query';
import React from 'react'
import getAllBlockedUser from '../services/getAllBlockedUser';
import BlockedUserItem from './BlockedUserItem';

function BlockedUserList() {
  const { data } = useQuery(["blocked_list"], getAllBlockedUser);

  return (
    <div className="mt-5 flex">
      {data?.map((e, index) => <BlockedUserItem key={index} email={e.email} /> )}    
    </div>
  )
}

export default BlockedUserList;
