import { Button, TextInput } from "flowbite-react";
import { useState } from "react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { httpsCallable } from "firebase/functions"
import FirebaseConfig from "./config/firebase";

export default function () {
  const queryClient = useQueryClient();
  const initAdminCloudFn = httpsCallable(FirebaseConfig.functions, "initAdmin")
  const calculateWinnerFn = httpsCallable(FirebaseConfig.functions, "calculateWinner")
  const [secretKey, setSecretKey] = useState("");
  const mutation = useMutation(async () => {
    let responseData = await initAdminCloudFn({ key: secretKey })
    return responseData
  });
  const calcWinMutation = useMutation(async () => {
    let responseData = await calculateWinnerFn({ key: secretKey })
    return responseData
  })
  const handleInitialized = () => {
    mutation.mutate()
  }
  const handleCalculateWinner = () => {
    calcWinMutation.mutate()
  }
  return <div>
    <div className="flex justify-center mt-10">
      <TextInput type="password" placeholder="secret key" value={secretKey} onChange={e => { setSecretKey(e.currentTarget.value); }} />
    </div>
    <div className="flex justify-center mt-10">
      <Button color={"success"} onClick={() => handleCalculateWinner()} disabled={calcWinMutation.isLoading}>Calculate Winner</Button>
      <Button color={"info"} onClick={() => handleInitialized()} disabled={mutation.isLoading}>Initialize Admin</Button>
    </div>
    <div>
      {mutation.isError && JSON.stringify(mutation.error)}
      {mutation.data && JSON.stringify(mutation.data)}
      {mutation.isSuccess && <div>success</div>}
    </div>
  </div>
}