// import DepositList from "./DepositList";

import { Button, Spinner, Toast } from "flowbite-react";
import { useState } from "react";
import useCustomMutation from "../../utils/useCustomMutation";
import adminChangePasswordService from "../../AdminChangePasswordService";
import { useNavigate } from "react-router-dom";
import { HiCheck } from "react-icons/hi";

type inputError = {
    oldPassword?: string,
    newPassword?: string,
    newPasswordConfirm?: string,
    other?: string
}
export default function () {
    const [inputError, setInputError] = useState<inputError>({})
    const [input, setInput] = useState({ oldPassword: "", newPassword: "", newPasswordConfirm: "" })
    const [actionMessage, setActionMessage] = useState("");
    const handleChangeInput = (e: React.FormEvent<HTMLInputElement>) => {
        setActionMessage("")
        setInputError({})
        let name = e.currentTarget.name
        let value = e.currentTarget.value
        setInput({ ...input, [name]: value })
    }
    const validateInput = () => {
        let error: inputError = {}

        if (input.newPasswordConfirm != input.newPassword) {
            error.newPasswordConfirm = "Password confirmation do not match"
        } else if (input.newPassword.length < 6) {
            error.newPassword = "Password must be at least 6 characters long"
        }
        if (input.newPasswordConfirm == input.oldPassword ) {
            error.newPassword = "New password must be different from your previous password"
        } 
        if (input.oldPassword == "") {
            error.oldPassword = "Old password is required"
        }
        if (input.newPassword == "") {
            error.newPassword = "New password is required"
        }
        if (input.newPasswordConfirm == "") {
            error.newPasswordConfirm = "New password confirmation is required"
        }
        if (error.oldPassword || error.newPassword || error.newPasswordConfirm) {
            setInputError({ ...inputError, ...error })
            return false;
        }
        return true
    }
    const handleSubmit = (e: any) => {
        e.preventDefault()
        if (validateInput()) {
            change_password_mutation.mutate({ oldPassword: input.oldPassword, newPassword: input.newPassword })
        }
    }
    const change_password_mutation = useCustomMutation(adminChangePasswordService, {
        onSuccess: (data) => {
            setInput({ oldPassword: "", newPassword: "", newPasswordConfirm: "" })
            setActionMessage("Change password success")
        },
        onError: (e: any) => { setInputError({ other: e.message }) }
    })
    return (
        <div className="w-full mx-10">
            <div className=" text-2xl font-bold">Change password</div>
            <form className="mt-5">
                <div className="relative z-0 w-full mb-6 group">
                    <input type="password" name="oldPassword" id="oldPassword" className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                        placeholder=" "
                        value={input.oldPassword}
                        onChange={handleChangeInput}
                        required />
                    <label htmlFor="email" className={`peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6 `}>Old password</label>
                    <div className="text-red-500 text-sm">{inputError.oldPassword}</div>
                </div>
                <div className="relative z-0 w-full mb-6 group">
                    <input type="password" name="newPassword" id="newPassword" className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                        placeholder=" "
                        value={input.newPassword}
                        onChange={handleChangeInput}
                        required />
                    <label htmlFor="password" className={`peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6 `}>New password</label>
                    <div className="text-red-500 text-sm">{inputError.newPassword}</div>
                </div>
                <div className="relative z-0 w-full mb-6 group">
                    <input type="password" name="newPasswordConfirm" id="newPasswordConfirm" className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                        placeholder=" "
                        value={input.newPasswordConfirm}
                        onChange={handleChangeInput}
                        required />
                    <label htmlFor="password" className={`peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6 `}>New password confirmation </label>
                    <div className="text-red-500 text-sm">{inputError.newPasswordConfirm}</div>
                </div>
                <div className=" text-red-500 text-sm mb-6">{inputError.other}</div>
                <Button onClick={handleSubmit} disabled={change_password_mutation.isLoading} className="bg-blue-700" type="submit" >
                    Submit <div className="ml-2">{change_password_mutation.isLoading && <Spinner />}</div>
                </Button>
                {actionMessage &&
                    <div className="flex flex-col gap-4 mt-5">
                        <Toast>
                            <div className="inline-flex h-8 w-8 shrink-0 items-center justify-center rounded-lg bg-green-100 text-green-500 dark:bg-green-800 dark:text-green-200">
                                <HiCheck className="h-5 w-5" />
                            </div>
                            <div className="ml-3 text-sm font-normal">
                                {actionMessage}
                            </div>
                        </Toast>
                    </div>
                }
            </form>
        </div>
    );
}
