import {
} from "firebase/firestore";
import { httpsCallable } from "firebase/functions";
import FirebaseConfig from "../../../config/firebase";

export default async function ({ email }: { email: string }) {
  const functions = FirebaseConfig.functions;
  const deleteAdmin = httpsCallable(functions, "deleteAdmin");
  const res = await deleteAdmin({
    email,
  });

  return res;
}
