import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface LoadingState {
  isFetching: boolean;
}

const initialState: LoadingState = {
  isFetching: false,
};

export const loadingSlice = createSlice({
  name: "loading",
  initialState,
  reducers: {
    changeLoadingState: (state, action: PayloadAction<LoadingState>) => {
      state.isFetching = action.payload.isFetching;
    },
  },
});

export const {
  changeLoadingState,
} = loadingSlice.actions;
export default loadingSlice.reducer;
