import { Button, Card } from "flowbite-react";
import ClaimType from "./ClaimType";
import { useQueryClient } from "@tanstack/react-query";
import { claimPrize } from "./apiClaim"
import useCustomMutation from "../../utils/useCustomMutation";

export default function ({ claim_item }: { claim_item: ClaimType }) {
  const queryClient = useQueryClient()
  const mutation = useCustomMutation(() => { return claimPrize(claim_item.uid!) }, {
    onSuccess: () => {
      queryClient.invalidateQueries(['unCompletedClaim'])
    }
  })
  return <Card>
    <div>{claim_item.user_uid}</div>
    <div>{claim_item.product_uid}</div>
    <div>{claim_item.phone_number}</div>
    <div>{claim_item.created_at.toDateString()}</div>
    <Button onClick={() => mutation.mutate()}>Claim</Button>
  </Card>
}