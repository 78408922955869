import {
  DocumentData,
  DocumentReference,
  DocumentSnapshot,
  Transaction,
  collection,
  runTransaction,
  query,
  where,
  getDocs,
  serverTimestamp,
} from "firebase/firestore";
import { doc } from "firebase/firestore";
import FirebaseConfig from "../../config/firebase";
import { DepositType } from "./DepositType";

export default async function (
  deposit: DepositType,
  action: "confirm" | "reject",
  reason: string = ""
) {
  await runTransaction(FirebaseConfig.db, async (transaction) => {
    const depositRef = doc(FirebaseConfig.db, "transactions", deposit.uid);
    const depositSnap = await transaction.get(depositRef);
    const profileRef = doc(FirebaseConfig.db, "profile", deposit.user_uid);
    const queryProfile = query(
      collection(FirebaseConfig.db, "profile"),
      where("referral_users", "array-contains", deposit.user_uid)
    );
    const docsRef = await getDocs(queryProfile);
    const referralRef = docsRef.docs.length > 0 ? docsRef.docs[0].ref : null;
    const referralSnap = referralRef
      ? await transaction.get(referralRef)
      : null;
    const profileSnap = await transaction.get(profileRef);

    if (!depositSnap.exists()) {
      throw new Error("deposit not exists");
    }
    if (action == "confirm")
      return await updateConfirmdeposit(
        transaction,
        depositRef,
        deposit,
        profileRef,
        profileSnap
      );
    await updateRejectdeposit(transaction, depositRef, reason);
  });
}
async function updateRejectdeposit(
  transaction: Transaction,
  depositRef: DocumentReference<DocumentData>,
  reason: string
) {
  await transaction.update(depositRef, {
    confirmed: true,
    is_cancel: true,
    reason: reason,
    rejected_date: serverTimestamp(),
  });
}

async function updateConfirmdeposit(
  transaction: Transaction,
  depositRef: DocumentReference<DocumentData>,
  deposit: DepositType,
  profileRef: DocumentReference<DocumentData>,
  profileSnap: DocumentSnapshot<DocumentData>
) {
  transaction.update(depositRef, {
    confirmed: true,
    accepted_date: serverTimestamp(),
  });

  if (!profileSnap.exists()) {
    transaction.set(profileRef, {
      usdt_amount: deposit.amount,
    });
  } else {
    let usdtData = profileSnap.data();
    let usdt_amount =
      usdtData && usdtData.usdt_amount && usdtData.usdt_amount > 0
        ? usdtData.usdt_amount
        : 0;

    transaction.update(profileRef, {
      usdt_amount: usdt_amount + deposit.amount,
    });
  }
}
