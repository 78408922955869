import { Button, Card } from "flowbite-react";
import { PaymentType } from "../PaymentType";
import { ConfirmPaymentModal, RejectPaymentModal } from "../PaymentModal";
import { DateTime } from "luxon";
import { useQuery } from "@tanstack/react-query";
import { collection, getDocs, limit, orderBy, query, where } from "firebase/firestore";
import FirebaseConfig from "../../../config/firebase";
export default function ({ item }: { item: PaymentType }) {
  let created_at;
  if (typeof item.created_at == "string") {
    created_at = DateTime.fromISO(item.created_at).toJSDate();
  } else {
    created_at = item.created_at
  }

  const { data, error, isLoading, refetch, isFetching, isFetched } = useQuery(["previous_payments", item.user_uid], async () => {
    let q = query(
      collection(FirebaseConfig.db, "payments"),
      where("user_uid", "==", item.user_uid),
      where("confirmed", "==", true),
      orderBy("created_at", "desc"),
      limit(3)
    );
    let querySnapshot = await getDocs(q);
    let data: Array<PaymentType> = [];

    querySnapshot.forEach((doc) => {
      let tempData = doc.data();
      data.push({
        uid: doc.id,
        email: tempData["email"],
        confirmed: tempData["confirmed"],
        created_at: new Date(tempData["created_at"]["seconds"] * 1000),
        is_cancel: tempData["is_cancel"],
        price: tempData["price"],
        ticket: tempData["ticket"],
        ticket_bonus: tempData["ticket_bonus"],
        reason: tempData['reason'],
        user_uid: tempData["user_uid"],
        phone: tempData["phone"],
        name: tempData["name"],
      });
    })
    return data
  }, { enabled: false, refetchOnMount: false })
  return < div className="w-full mr-2" key={item.uid}>
    <Card className="w-full">
      <div className="flex flex-col">
        <Button onClick={() => { refetch() }} disabled={isFetching}>{!isFetched ? "get previous Payment" : "refetch previous payment"}</Button>
        <div className="flex gap-x-8">
          <div className="flex-0 w-12">previous payment</div>
          <div className="flex-1 font-bold">
            <ul>
              {data && data.length == 0 && "empty"}
              {data && data.map((item, index) => {
                return <li key={index}>
                  <div className={`flex gap-x-2 ${item.is_cancel ? "text-red-500" : " text-green-500"}`}>
                    <div>{item.is_cancel ? "reject" : "confirmed"}</div>
                    <div>{item.ticket}</div>
                    <div>{typeof item.created_at == "string" ? item.created_at : item.created_at.toDateString()}</div>
                    <div>{item.reason}</div>
                  </div>
                </li>
              })}
            </ul></div>
        </div>
        <div className="flex gap-x-4">
          <div className="flex-0 w-12">Date</div>
          <div className="flex-1 font-bold">{created_at.toDateString()}</div>
        </div>
        <div className="flex gap-x-4 flex-wrap">
          <div className="flex-0 w-12">Email</div>
          <div className="flex-1 font-bold">{item.email}</div>
        </div>
        <div className="flex gap-x-4">
          <div className="flex-0 w-12">Price</div>
          <div className="flex-1 font-bold">{item.price}</div>
        </div>
        <div className="flex gap-x-4">
          <div className="flex-0 w-12">Ticket</div>
          <div className="flex-1 font-bold">{item.ticket + item.ticket_bonus}</div>
        </div>
        <div className=" border-t-2 border-gray-200">
          <div className="font-bold">Confirmation Detail</div>
          <div>{item.name}</div>
          <div>{item.phone}</div>
        </div>
        <div className="flex gap-x-4 mt-2">
          <div className="flex-1">
            <ConfirmPaymentModal selected_payment={item} />
          </div>
          <div className="flex-1">
            <RejectPaymentModal selected_payment={item} />
          </div>
        </div>
      </div>
    </Card>
  </div>
}