import React from 'react'
import { CgChevronRight } from 'react-icons/cg'
import { TokenPaymentType } from '../../token/TokenPaymentType';

function PaymentHistoryItem({ payId, data }: { payId: number, data: TokenPaymentType }) {

  function formatTime(timeString: string) {
    const date = new Date(timeString);
    const month = date.toLocaleString('en-US', { month: 'short' })
    return `${month} ${String(date.getDate()).padStart(2, "0")}, ${date.getFullYear()}`
  }
  
  return (
    <div className={`${payId % 2 === 0 ? 'bg-slate-200': 'bg-slate-100'} rounded-md relative flex justify-between min-w-[750px] mt-2 px-6 py-3 cursor-default`}>
      <p className="font-medium min-w-[56px] lg:min-w-[170px] my-auto text-sm">{payId}</p>
      <p className="font-medium flex-1 my-auto">{(data.paid_usdt_amount || 0).toLocaleString()} USDT</p>
      <div className="flex gap-2 flex-1">
        <img className="w-6 h-6 my-auto" src="/adsc-token.svg" alt="" />
        <p className="font-medium my-auto">{data.token.toLocaleString()} ADSC</p>
      </div>
      <div className="flex gap-2 flex-1">
        <img className="w-6 h-6 my-auto" src="/adsc-token.svg" alt="" />
        <p className="font-medium my-auto">{(data.referral_token || 0).toLocaleString()} ADSC</p>
      </div>

      <div className="flex-1 font-medium pr-2">{data.email}</div>
      
      <p className="font-medium flex-1 my-auto">{formatTime(data.created_at.toString())} </p>
      <div className="flex flex-1 gap-2">
        <img className="w-6 h-6 my-auto" src="/adsc-token.svg" alt="" />
        <p className="font-medium my-auto">{(data.token).toLocaleString()} ADSC</p>
      </div>
    </div>
  )
}

export default PaymentHistoryItem;
