import { Button } from "flowbite-react";
import ProductList from "./ProductList";
import { useState } from "react";
import CreateAuctionModal from "./CreateProductModal";
import { useAppSelector } from "../../hook";

export default function () {
  const [showCreateModal, setShowCreateModal] = useState(false);
  const permissions = useAppSelector((states) => states.userPermissions.permissions);
  
  if (!permissions.includes("product") && !permissions.includes("all")) return(
    <div className="w-full flex flex-col justify-center">
      <p className="text-2xl text-center my-auto animate-bounce">You don't have permission to access this feature</p>
    </div>
  )
  
  return <div className="w-full mx-2">
    <CreateAuctionModal show={showCreateModal} onClose={() => setShowCreateModal(false)}/>
    <Button className="w-full" onClick={() => { setShowCreateModal(true) }}>Create Auction</Button>
    <div className=" text-lg font-bold mt-2">Auctions</div>
    <ProductList />
  </div >
}