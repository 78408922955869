import { Button, Card } from "flowbite-react";
import { DepositType } from "../DepositType";
import { ConfirmDepositModal, RejectDepositModal } from "../DepositModal";
import { DateTime } from "luxon";
import { useQuery } from "@tanstack/react-query";
import { collection, getDocs, limit, orderBy, query, where } from "firebase/firestore";
import FirebaseConfig from "../../../config/firebase";
export default function ({ item, refetchUnconfirmed }: { item: DepositType, refetchUnconfirmed: () => void }) {
    let created_at;
    if (typeof item.created_at == "string") {
        created_at = DateTime.fromISO(item.created_at).toJSDate();
    } else {
        created_at = item.created_at
    }

    const { data, error, isLoading, refetch, isFetching, isFetched } = useQuery(["previous_deposit_payments", item.user_uid], async () => {
        let q = query(
            collection(FirebaseConfig.db, "deposit_payments"),
            where("user_uid", "==", item.user_uid),
            where("confirmed", "==", true),
            orderBy("created_at", "desc"),
            limit(3)
        );
        let querySnapshot = await getDocs(q);
        let data: Array<DepositType> = [];

        querySnapshot.forEach((doc) => {
            let tempData = doc.data();
            data.push({
                uid: doc.id,
                confirmed: tempData["confirmed"],
                created_at: new Date(tempData["created_at"]["seconds"] * 1000),
                is_cancel: tempData["is_cancel"],
                amount: tempData["amount"],
                transaction_hash: tempData["transaction_hash"],
                user_uid: tempData["user_uid"],
                email: tempData["email"],
                reason: tempData["reason"],
                image: tempData["image"],
            });
        })
        return data
    }, { enabled: false, refetchOnMount: false })
    return < div className="w-full mr-2" key={item.uid}>
        <Card className="w-full">
            <div className="flex flex-col">
                <Button onClick={() => { refetch() }} disabled={isFetching}>{!isFetched ? "get previous deposit" : "refetch previous deposit"}</Button>
                <div className="flex">
                    <div className="flex-1">
                        <div className="flex gap-x-8">
                            <div className="flex-0 w-12">previous deposit</div>
                            <div className="flex-1 font-bold">
                                <ul>
                                    {data && data.length == 0 && "empty"}
                                    {data && data.map((item, index) => {
                                        return <li key={index}>
                                            <div className={`flex gap-x-2 ${item.is_cancel ? "text-red-500" : " text-green-500"}`}>
                                                <div>{item.is_cancel ? "reject" : "confirmed"}</div>
                                                <div>{item.amount}</div>
                                                <div>{typeof item.created_at == "string" ? item.created_at : item.created_at.toDateString()}</div>
                                                <div>{item.reason}</div>
                                            </div>
                                        </li>
                                    })}
                                </ul></div>
                        </div>
                        <div className="flex gap-x-4">
                            <div className="flex-0 w-12">Date</div>
                            <div className="flex-1 font-bold">{created_at.toDateString()}</div>
                        </div>
                        <div className="flex gap-x-4 flex-wrap">
                            <div className="flex-0 w-12">Amount</div>
                            <div className="flex-1 font-bold">{item.amount || "Failed to get amount"}</div>
                        </div>
                        <div className=" border-t-2 border-gray-200">
                            <div className="font-bold">Confirmation Detail</div>
                            <div className="flex gap-x-4">
                                <div className="flex-0 w-[100px]">Transaction Hash</div>
                                <div onClick={() => window.open(`https://polygonscan.com/tx/${item.transaction_hash}`)} 
                                className="flex-1 font-bold cursor-pointer underline text-blue-500">{item.transaction_hash}</div>
                            </div>
                            <div className="flex gap-x-4 flex-wrap">
                                <div className="flex-0 w-[100px]">Email</div>
                                <div className="flex-1 font-bold">{item.email}</div>
                            </div>
                        </div>

                    </div>
                    <div className="flex-1 m-2 ">
                        <img src={item.image} />
                    </div>
                </div>
                <div className="flex gap-x-4 mt-2">
                    <div className="flex-1">
                        <ConfirmDepositModal refetch={refetchUnconfirmed} selected_payment={item} />
                    </div>
                    <div className="flex-1">
                        <RejectDepositModal refetch={refetchUnconfirmed} selected_payment={item} />
                    </div>
                </div>
            </div>
        </Card>
    </div>
}