import { addDoc, collection, doc, serverTimestamp } from "firebase/firestore";
import FirebaseConfig from "../../../config/firebase";

export default async function ({ platform, title, link }: { platform: string, title: string, link: string }) {
  link = link.replace(/^https?:\/\//, '');
  await addDoc(collection(FirebaseConfig.db, "social_media_news"), {
    platform,
    title,
    link,
    created_at: serverTimestamp(),
  });
}